// import React, { useState } from 'react';
// import { Dialog, DialogActions, DialogContent, TextField, Button } from '@mui/material';
// import apiClient from '../../config/axiosConfig';
// import { toast } from 'react-toastify';
// const ApplicationFormDialog = ({ open, handleDialogClose,courseInfo }) => {
//     const [formData, setFormData] = useState({
//         name: '',
//         email: '',
//         phone: '',
//         address:'',
//         graduatedCollege: ''  ,
//         qualification:''
//     });

//     console.log("course Info:",courseInfo[0].courseType);
//     // Handle input field changes
//     const handleInputChange = (event) => {
//         const { name, value } = event.target;
//         setFormData({
//             ...formData,
//             [name]: value
//         });
//     };
//     const today = new Date();
//     const formattedDate = today.toISOString().split('T')[0];
//     console.log(formattedDate); 

//     // Handle form submission
//     const handleSubmit = (event) => {
//         event.preventDefault();
//         console.log('Form Data Submitted:', formData); 
//         const studentApplication ={
//             s_name: formData.name,
//             email: formData.email,
//             s_contact: formData.phone,
//             s_address: formData.address,
//             graduated_college: formData.graduatedCollege,
//             admission_on: formattedDate,
//             qualification: formData.qualification,
//             destination:courseInfo[0].courseType,
//             preparationTest:courseInfo[0].preparation
//         }
//         console.log("Send above application:",studentApplication);
//         apiClient.post(`/register`,studentApplication).then((response)=>{
//             console.log(response)
//             if(response.data.success){
//                 toast.success("Registration Success. Thank you.",{
//                   position: "top-right",
//                   autoClose: 5000,
//                   closeOnClick: true,
//                   pauseOnHover: true,
//                 });
//               }else{
//                 toast.error("Sorry, Something wrong.",{
//                   position: "top-right",
//                   autoClose: 5000,
//                   closeOnClick: true,
//                   pauseOnHover: true,
//                 });
//               }
//         }).catch((error)=>{
//             console.log(error);
//         });
//         // Close the dialog
//         handleDialogClose();
//     };

//     return (
//         <Dialog open={open} onClose={handleDialogClose}>
//             <DialogContent>
//                 <form onSubmit={handleSubmit}>
//                     <TextField
//                         fullWidth
//                         margin="normal"
//                         label="Student Name"
//                         name="name"
//                         value={formData.name}
//                         onChange={handleInputChange}
//                         required
//                     />
//                     <TextField
//                         fullWidth
//                         margin="normal"
//                         label="Email"
//                         type="email"
//                         name="email"
//                         value={formData.email}
//                         onChange={handleInputChange}
//                         required
//                     />
//                     <TextField
//                         fullWidth
//                         margin="normal"
//                         label="Phone Number"
//                         type="tel"
//                         name="phone"
//                         value={formData.phone}
//                         onChange={handleInputChange}
//                         required
//                     />
//                     <TextField
//                         fullWidth
//                         margin="normal"
//                         label="Address"
//                         type="tel"
//                         name="address"
//                         value={formData.address}
//                         onChange={handleInputChange}
//                         required
//                     />
//                     <TextField
//                         fullWidth
//                         margin="normal"
//                         label="Graduated College"
//                         name="graduatedCollege"  // Use the key name as the name attribute
//                         value={formData.graduatedCollege}
//                         onChange={handleInputChange}
//                         required
//                     />
//                     <TextField
//                         fullWidth
//                         margin="normal"
//                         label="Qualification"
//                         name="qualification"  // Use the key name as the name attribute
//                         value={formData.qualification}
//                         onChange={handleInputChange}
//                         required
//                     />
//                 </form>
//             </DialogContent>
//             <DialogActions>
//                 <Button type="submit" variant="contained" color="primary" onClick={handleSubmit}>
//                     Submit Application
//                 </Button>
//                 <Button onClick={handleDialogClose} color="primary">
//                     Close
//                 </Button>
//             </DialogActions>
//         </Dialog>
//   );
// };

// export default ApplicationFormDialog;



import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, TextField, Button } from '@mui/material';
import apiClient from '../../config/axiosConfig';
import { toast } from 'react-toastify';

const ApplicationFormDialog = ({ open, handleDialogClose, courseInfo }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        address: '',
        graduatedCollege: '',
        qualification: ''
    });

    // Ensure courseInfo is an object and has the properties before accessing them
    const courseType = courseInfo?.courseType || ''; // Fallback to empty string if courseInfo or courseType is undefined
    const preparation = courseInfo?.preparation || ''; // Fallback to empty string if preparation is undefined

    console.log("course Info:", courseType);  // Debugging output

    // Handle input field changes
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    console.log(formattedDate);

    // Handle form submission
    const handleSubmit = (event) => {
        event.preventDefault();

        // Check if all required fields are filled
        const isFormValid = Object.values(formData).every(value => value.trim() !== '');
        if (!isFormValid) {
            toast.error("Please fill in all the fields.", {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
            });
            return;  // Do not submit if the form is invalid
        }

        console.log('Form Data Submitted:', formData);
        
        const studentApplication = {
            s_name: formData.name,
            email: formData.email,
            s_contact: formData.phone,
            s_address: formData.address,
            graduated_college: formData.graduatedCollege,
            admission_on: formattedDate,
            qualification: formData.qualification,
            destination: courseType,  // Use courseType directly
            preparationTest: preparation // Use preparation directly
        };
        console.log("Send above application:", studentApplication);

        apiClient.post(`/register`, studentApplication).then((response) => {
            console.log(response);
            if (response.data.success) {
                toast.success("Registration Success. Thank you.", {
                    position: "top-right",
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            } else {
                toast.error("Sorry, Something went wrong.", {
                    position: "top-right",
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
        }).catch((error) => {
            console.log(error);
        });

        // Close the dialog
        handleDialogClose();
    };

    return (
        <Dialog open={open} onClose={handleDialogClose}>
            <DialogContent>
                <form onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Student Name"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Email"
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Phone Number"
                        type="tel"
                        name="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                        required
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Address"
                        name="address"
                        value={formData.address}
                        onChange={handleInputChange}
                        required
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Graduated College"
                        name="graduatedCollege"
                        value={formData.graduatedCollege}
                        onChange={handleInputChange}
                        required
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Qualification"
                        name="qualification"
                        value={formData.qualification}
                        onChange={handleInputChange}
                        required
                    />
                </form>
            </DialogContent>
            <DialogActions>
                <Button type="submit" variant="contained" color="primary" onClick={handleSubmit}>
                    Submit Application
                </Button>
                <Button onClick={handleDialogClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ApplicationFormDialog;
