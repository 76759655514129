import React from 'react';
import { Box, Typography, Container, Card, CardContent, CardMedia, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FooterBar from '../../../layout/footer_bar/footer_bar_main';
import { useLocation } from 'react-router';
import { Buffer } from 'buffer';
import SocialMediaBar from '../../SocialMedia/socialMedia';

function CountryDetail() {
  const location = useLocation();
  const country = location.state;

  if (!country) {
    return <Typography variant="h6" align="center">Country data is missing or not available</Typography>;
  }

  const flagData = country.flag ? `url(data:image/${country.imageFormat};base64,${Buffer.from(country.flag).toString('base64')})` : null;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh',}}>
      <Container sx={{ flex: 1, padding: '40px 20px' }}>
        {/* Title Section */}
        <Typography
          variant="h3"
          gutterBottom
          align="center"
          sx={{
            fontWeight: 'bold',
            color: '#003366',
            fontFamily: "Roboto, sans-serif",
            textTransform: "uppercase",
            fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
            letterSpacing: '2px',
            marginBottom: '30px',
          }}
        >
          {country.countryName}
        </Typography>

        {/* Flag Image Section in Card */}
        {flagData && (
           <Box sx={{
            display: 'flex',
            justifyContent: 'center',  // Horizontally center the card
            marginBottom: '40px',
          }}>
          <Card sx={{
            // backgroundColor:'red',
            // width: '100%',
            width: { xs: '90%', sm: '80%', md: '40%' },
            height: { xs: '250px', sm: '350px', md: '400px' },
            borderRadius: '12px',
            boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)',
            overflow: 'hidden',
            marginBottom: '40px',
            transition: 'transform 0.3s ease-in-out',
            '&:hover': {
              transform: 'scale(1.05)',
            },
          }}>
            <CardMedia
              component="div"
              sx={{
                width: '100%',
                height: '100%',
                backgroundImage: flagData,
                backgroundPosition: 'center',
                backgroundSize: 'contain',
              }}
            />
          </Card>
        </Box>
        )}

        {/* Why Study Here Section */}
        <Box sx={{ textAlign: 'center', marginBottom: '40px' }}>
          <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '20px', fontSize: { xs: '1.5rem', sm: '2rem' } }}>
            Why Study Here?
          </Typography>
          <Typography variant="body1" sx={{
            fontFamily: "Poppins, sans-serif",
            textAlign: 'justify',
            marginBottom: '20px',
            color: '#333',
            fontSize: { xs: '1rem', sm: '1.125rem' },
          }}>
            {country.reasonToChoose}
          </Typography>
        </Box>

        {/* Guidelines Section */}
        <Box sx={{ marginBottom: '40px' }}>
          <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '20px', textAlign: 'center', fontSize: { xs: '1.5rem', sm: '2rem' } }}>
            Guidelines:
          </Typography>
          {country.admissionGuideline.length > 0 ? (
            <Box sx={{ textAlign: 'left', paddingLeft: '20px', fontSize: { xs: '1rem', sm: '1.125rem' } }}>
              <ul style={{ listStyleType: 'disc' }}>
                {country.admissionGuideline.map((guideline, index) => (
                  <li key={index}>{guideline}</li>
                ))}
              </ul>
            </Box>
          ) : (
            <Typography variant="body1" align="center">No Guidelines available.</Typography>
          )}
        </Box>

        {/* Popular Universities Section */}
        <Box sx={{ marginBottom: '40px' }}>
          <Typography variant="h5" sx={{ fontWeight: 'bold', textAlign: 'center', fontSize: { xs: '1.5rem', sm: '2rem' }, marginBottom: '20px' }}>
            Popular Universities:
          </Typography>
          {country.popularUniversities && country.popularUniversities.length > 0 ? (
            <Box sx={{ textAlign: 'left', paddingLeft: '20px', fontSize: { xs: '1rem', sm: '1.125rem' } }}>
              <ol>
                {country.popularUniversities.map((university, index) => (
                  <li key={index}>{university}</li>
                ))}
              </ol>
            </Box>
          ) : (
            <Typography variant="body1" align="center">No popular universities available.</Typography>
          )}
        </Box>

        {/* FAQ Section */}
        <Box sx={{ marginBottom: '40px' }}>
          <Typography variant="h5" sx={{ fontWeight: 'bold', textAlign: 'center', fontSize: { xs: '1.5rem', sm: '2rem' }, marginBottom: '20px' }}>
            Frequently Asked Questions:
          </Typography>
          <Box>
            {country.faq && country.faq.length > 0 ? (
              country.faq.map((item, index) => (
                <Accordion key={index} sx={{ marginBottom: '10px', '&:before': { display: 'none' } }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${index}-content`}
                    id={`panel${index}-header`}
                    sx={{
                      '& .MuiAccordionSummary-expandIcon': { color: '#fff' },
                    }}
                  >
                    <Typography sx={{ fontWeight: 'bold' }}>{item.faqQuestion}</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ backgroundColor: '#f5f5f5' }}>
                    <Typography>{item.faqAnswer}</Typography>
                  </AccordionDetails>
                </Accordion>
              ))
            ) : (
              <Typography variant="body1" align="center">No FAQ data available.</Typography>
            )}
          </Box>
        </Box>

        <SocialMediaBar />
      </Container>

      <FooterBar />
    </Box>
  );
}

export default CountryDetail;
