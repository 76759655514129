import React, { useEffect, useState } from "react";
import {Box,Typography, Container, Card, CardContent } from '@mui/material';
import Slider from "react-slick";
import { Link } from 'react-router-dom'; 
import { Buffer } from "buffer";
// import courses from './NepalCourseData';
import apiClient from '../../config/axiosConfig';

const StudyNepalSlider = ({nepaliCourses})=>{
    // const [nepaliCourses,setNepaliCourses] = useState([]);
    // useEffect(()=>{
    //     const fetchNepaliCourses = ()=>{
    //         apiClient.get(`/studynepalicourses`).then((response)=>{
    //             // console.log(response.data.studyNepalCourses);
    //             setNepaliCourses(response.data.studyNepalCourses);
    //         }).catch((error)=>{
    //             console.log(error)
    //         });
    //     }
    //     fetchNepaliCourses();
    // },[])
    const settings = {
        dots: true,
        infinite: true,
        speed: 600,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: false,
        centerPadding: '10px',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return(
        <Container maxWidth="lg"
            >
                <Typography variant="h4" component="h1" gutterBottom align="center" sx={{ fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem',fontFamily:"Roboto-Serif"}, 
                        fontWeight: 'bold',
                        color: '#003366',
                        textTransform: 'uppercase', 
                        mt: { xs: 4, md: 6 } }}>
                    Study in Nepal
                </Typography>
                <Typography variant="body1" textAlign="center" color="textSecondary" component="p" 
                    sx={{ 
                        mb: { xs: 4, md: 6 } ,
                        fontFamily:"Poppins",
                        // fontSize: {xs:"12px",sm:"16px",md:"18px",lg:"22px"},
                        textTransform: "capitalize"
                        // backgroundColor: "yellow"
                    }}>
                    Discover various academic courses offered in Nepal, helping students achieve their educational goals.
                </Typography>

                {/* Course Slider */}
                <Slider {...settings} style={{ backgroundColor: "transparent" }}>
                    {nepaliCourses.map((course) => (
                        <div key={course.id}>
                            <Card sx={{
                                height: 'auto',
                                borderRadius: '12px',
                                transition: 'transform 0.2s',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                },
                                margin: '10px 10px',
                            }}>
                                <Link to={{ pathname: "/desc"}} state= { course } style={{ textDecoration: 'none', color: 'inherit', height: '100%' }}>
                                    <CardContent 
                                        sx={{ 
                                            display: 'flex', 
                                            flexDirection: 'column', 
                                            alignItems: 'center',
                                        }}>
                                        <Box
                                            sx={{
                                                width: '100%', 
                                                height: '210px', 
                                                borderRadius: '12px', 
                                                marginBottom: '16px',
                                                backgroundImage:`url(data:image/${course.imageFormat};base64,${Buffer.from(course.courseImage).toString('base64')})`,
                                                backgroundPosition:"center",
                                                backgroundSize: "cover",
                                                backgroundRepeat: "no-repeat"
                                            }}
                                        />
                                        
                                        <Typography variant="h6" gutterBottom align="center" 
                                            sx={{ 
                                                fontWeight: 'bold',
                                                fontSize:{xs:"14px",sm:"16px",md:"20px"},
                                                fontFamily:"Roboto-Serif"
                                            }}>
                                            {course.courseTitle}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" align="center"
                                            sx={{
                                                fontSize:{xs:"12px",sm:"14px",md:"16px"},
                                                fontFamily:"Poppins"
                                            }}
                                        >
                                            {course.overview.split(" ").slice(0, 10).join(" ")}
                                            {course.overview.split(" ").length > 30 ? "..." : ""}
                                        </Typography>
                                    </CardContent>
                                </Link>
                            </Card>
                        </div>
                    ))}
                </Slider>
            </Container>
    )
}
export default StudyNepalSlider;