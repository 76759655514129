// import React, { useState, useEffect } from 'react';
// import {
//     Box,
//     Typography,
//     Container,
//     Grid,
//     Card,
//     CardContent,
//     Button,
//     Accordion,
//     AccordionSummary,
//     AccordionDetails,
// } from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import FooterBar from '../../layout/footer_bar/footer_bar_main';
// import ApplicationFormDialog from '../Courses/courseform';
// import { useLocation } from 'react-router';

// const AbroadCourseDescriptionPage = () => {
//     const [dialogOpen, setDialogOpen] = useState(false);
    
//     const location = useLocation();
//     const coursesReceived = location.state || {}; // Default to empty object if no state is passed
//     console.log("Abroad Courses:", coursesReceived);

//     // Safely extract course title and prepare for splitting
//     const courseTitle = coursesReceived.courseTitle || ''; // Default to empty string if undefined
//     const preparation = courseTitle.split('(')[0].split(' ')[0]; // Split safely if courseTitle is non-empty

//     const courseInfo = { courseType: 'Study Abroad', preparation }; // Safely create the course info object

//     const handleDialogOpen = () => {
//         setDialogOpen(true);
//     };

//     const handleDialogClose = () => {
//         setDialogOpen(false);
//     };

//     return (
//         <Box sx={{ marginTop: "5rem" }}>
//             <Container maxWidth="lg">
//                 <Typography variant="h4" component="h1" gutterBottom align="center" sx={{ fontWeight: 'bold', color: '#003366' }}>
//                     {coursesReceived.courseTitle}
//                 </Typography>

//                 <Typography variant="body1" paragraph>
//                     {coursesReceived.overview}
//                 </Typography>

//                 <Typography variant="h5" component="h2" gutterBottom>
//                     Test Format
//                 </Typography>
//                 <Grid container spacing={2}>
//                     {coursesReceived.testFormat?.map((format, index) => (
//                         <Grid item xs={12} sm={6} md={3} key={index}>
//                             <Card sx={{ height: '100%' }}>
//                                 <CardContent>
//                                     <Typography variant="h6">{format.topic}</Typography>
//                                     <Typography variant="body2">{format.description}</Typography>
//                                 </CardContent>
//                             </Card>
//                         </Grid>
//                     ))}
//                 </Grid>

//                 <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
//                     Preparation Tips
//                 </Typography>
//                 <ul>
//                     {coursesReceived.preparationTips?.map((tip, index) => (
//                         <li key={index}>
//                             <Typography variant="body1">{tip}</Typography>
//                         </li>
//                     ))}
//                 </ul>

//                 <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
//                     Frequently Asked Questions
//                 </Typography>
//                 {coursesReceived.faq?.map((faq, index) => (
//                     <Accordion key={index}>
//                         <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}-content`} id={`panel${index}-header`}>
//                             <Typography>{faq.question}</Typography>
//                         </AccordionSummary>
//                         <AccordionDetails>
//                             <Typography>{faq.answer}</Typography>
//                         </AccordionDetails>
//                     </Accordion>
//                 ))}

//                 <Box sx={{ textAlign: 'center', mt: 4 }}>
//                     <Button
//                         variant="contained"
//                         onClick={handleDialogOpen}
//                         sx={{
//                             mr: 2,
//                             backgroundImage: 'linear-gradient(90deg, #49BBBD 0%, #136CB5 100%)',
//                             color: 'white',
//                             '&:hover': {
//                                 backgroundImage: 'linear-gradient(90deg, #136CB5 0%, #49BBBD 100%)',
//                             },
//                         }}
//                     >
//                         Apply Now
//                     </Button>

//                     <Button variant="outlined" color="secondary" href="/contact">
//                         Contact Us
//                     </Button>
//                 </Box>
//             </Container>

//             {/* Dialog for Apply Now */}
//             <ApplicationFormDialog open={dialogOpen} handleDialogClose={handleDialogClose} courseInfo={courseInfo} />

//             <FooterBar />
//         </Box>
//     );
// };

// export default AbroadCourseDescriptionPage;




import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Container,
    Grid,
    Card,
    CardContent,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FooterBar from '../../layout/footer_bar/footer_bar_main';
import ApplicationFormDialog from '../Courses/courseform';
import { useLocation } from 'react-router';
import SocialMediaBar from '../SocialMedia/socialMedia';

const AbroadCourseDescriptionPage = () => {
    const [dialogOpen, setDialogOpen] = useState(false);

    const location = useLocation();
    const coursesReceived = location.state || {}; // Default to empty object if no state is passed
    console.log("Abroad Courses:", coursesReceived);

    
    // Safely extract course title and prepare for splitting
    const courseTitle = coursesReceived.courseTitle || '';  // Default to empty string if undefined
    const preparation = courseTitle.split('(')[0].split(' ')[0];  // Split safely if courseTitle is non-empty

    const courseInfo = { courseType: 'Study Abroad', preparation }; // Safely create the course info object

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    return (
        <Box sx={{ marginTop: "5rem" }}>
            <Container maxWidth="lg">
                <Typography variant="h4" component="h1" gutterBottom align="center" sx={{ fontWeight: 'bold', color: '#003366' }}>
                    {coursesReceived.courseTitle}
                </Typography>

                <Typography variant="body1" paragraph>
                    {coursesReceived.overview}
                </Typography>

                <Typography variant="h5" component="h2" gutterBottom>
                    Test Format
                </Typography>
                <Grid container spacing={2}>
                    {coursesReceived.testFormat?.map((format, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index}>
                            <Card sx={{ height: '100%' }}>
                                <CardContent>
                                    <Typography variant="h6">{format.topic}</Typography>
                                    <Typography variant="body2">{format.description}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>

                <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
                    Preparation Tips
                </Typography>
                <ul>
                    {coursesReceived.preparationTips?.map((tip, index) => (
                        <li key={index}>
                            <Typography variant="body1">{tip}</Typography>
                        </li>
                    ))}
                </ul>

                <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
                    Frequently Asked Questions
                </Typography>
                {coursesReceived.faq?.map((faq, index) => (
                    <Accordion key={index}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}-content`} id={`panel${index}-header`}>
                            <Typography>{faq.question}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>{faq.answer}</Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}

                <Box sx={{ textAlign: 'center', mt: 4 }}>
                    <Button variant="contained" onClick={handleDialogOpen} sx={{
                        mr: 2,
                        backgroundImage: 'linear-gradient(90deg, #49BBBD 0%, #136CB5 100%)',
                        color: 'white',
                        '&:hover': { backgroundImage: 'linear-gradient(90deg, #136CB5 0%, #49BBBD 100%)' }
                    }}>
                        Apply Now
                    </Button>

                    <Button variant="outlined" color="secondary" href="/contact">
                        Contact Us
                    </Button>
                </Box>
                <SocialMediaBar/>
            </Container>

            {/* Dialog for Apply Now */}
            <ApplicationFormDialog open={dialogOpen} handleDialogClose={handleDialogClose} courseInfo={courseInfo} />

            <FooterBar />
        </Box>
    );
};

export default AbroadCourseDescriptionPage;
