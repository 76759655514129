import React, { useEffect, useState } from 'react';
import { Box, Typography, Container } from '@mui/material';
import AccordionDesign from './bachelorPrograms.accrodian';
import apiClient from '../../config/axiosConfig';

const BachelorPrograms = () => {
  const [programsData, setProgramsData] = useState([]);

  useEffect(() => {
    const fetchProgramData = () => {
      apiClient
        .get('/universityprograms')
        .then((response) => {
          setProgramsData(response.data.universityDetail);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchProgramData();
  }, []);

  return (
    <Container
      sx={{
        padding: { xs: '3rem 10px', md: '3rem 0px' },
      }}
    >
      <Typography
        variant="h4"
        align="center"
        sx={{
          fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
          fontWeight: 'bold',
          color: '#003366',
          textTransform: 'uppercase',
        }}
        gutterBottom
      >
        Management And IT Program's in Nepal
      </Typography>

      <Box sx={{ padding: '0px 20px' }}>
        {programsData.map((university, index) => (
          <AccordionDesign key={index} universityPrograms={university} />
        ))}
      </Box>
    </Container>
  );
};

export default BachelorPrograms;
