import React from 'react';
import {
  Box,
  Container,
  Typography,
  Link,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {
  ContactMail as ContactMailIcon,
  Phone as PhoneIcon,
  LocationOn as LocationOnIcon,
} from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';
import logo from '../../assets/eduLogo.png';
import SocialMedia from './social_media';
// Navigation items for footer
const footerNavItems = [
  { name: "About Us", to: "/about" },
  { name: "Study in Nepal", to: "/study-in-nepal" },
  { name: "Study Abroad", to: "/study-abroad" },
];

const FooterBar = () => {
  return (
    <Box 
      sx={{ 
        width: '100%', 
        background: 'linear-gradient(90deg, #49BBBD 0%, #136CB5 100%)', 
        color: '#ffffff', 
        marginTop: '4rem', 
        height: 'auto',
  
        }}>
      <Container 
        // sx={{backgroundColor:"yellow"}}
      >
        <Grid container spacing={3} sx={{ flexDirection: { xs: 'column', sm: 'row' } }}>
          
          {/* Logo and Contact Information */}
          <Grid item xs={12} sm={6} md={4} >
            <Box
              sx={{
                height:"auto",
                // backgroundColor:"blue",
                width:"100%",
                display:"flex",
                alignItems:"center",
                mt: 2
              }}
            >
                <Box 
                  sx={{ 
                    display: 'flex',
                    alignItems: 'center', 
                    marginRight: { xs: '0.6rem', sm: '2rem' },
                    backgroundColor:'#ffffff',
                    display:"flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius:'50%',
                    // border:'1px solid red',
                    // marginLeft:{xs:'-1.2rem'},
                    // width: { xs: '40px', sm: '60px' },
                    // height: { xs: '40px', sm: '60px' },
                    padding:"3px"
                  }}
                >
                  <Link to="/" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
                    <Box 
                      
                      sx={{
                        backgroundImage:`url(${logo})`,
                        backgroundPosition:"center",
                        backgroundSize:"100%",
                        backgroundRepeat:"no-repeat",
                        height: { xs: '40px', sm: '60px',md:"80px" },
                        width: {xs:'40px',sm:'60px',md:"80px"},
                        // border:"1px solid red",
                        borderRadius:'50%',
                        // marginRight:"0.5rem"
                        // marginLeft:{xs:'-1.2rem'},
                      }} 
                    />
                  </Link>
                </Box>
                <Typography variant="h6"  
                  sx={{ 
                    fontFamily:'Roboto-Serif', 
                    fontSize: { xs: '1rem', sm: '1.25rem',md:"2rem"},
                    marginLeft:"2px",
                    fontWeight: "600"
                    }}>Educ@tion.com</Typography>
                
            </Box>
            <Box 
              sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'flex-start', 
                fontFamily: 'Poppins',
                paddingLeft:{xs:'5px',sm:"20px"},
                mt:{xs:"5px",sm:1}
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center',marginTop:'0.5rem', }}>
                  <LocationOnIcon sx={{ fontSize: { xs: '1.5rem', sm: '1.5rem' },marginRight:"3px" }} />
                  {/* <span style={{ textTransform: 'capitalize' }}>O</span>pposite Shankar Dev Campus (SDC) */}
                  <Typography variant="h6" sx={{ fontFamily: 'Poppins', fontSize: { xs: '0.92rem', sm: '1.5rem' } }}>Opposite Shankar Dev Campus</Typography>
                </Box>
                
                <Typography sx={{ display: 'flex', flexDirection: 'column', marginLeft: '1.7rem', fontFamily: 'Poppins',fontSize: {xs: '0.92rem', sm: '1.2rem'  } }}>
                  Putalisadak, Kathmandu
                </Typography>
            </Box>
            <Box 
              sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'flex-start', 
                fontFamily: 'Poppins',
                paddingLeft:{xs:'5px',sm:"20px"},
                mt:{xs:"5px",sm:1}
              }}>
                <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', fontFamily: 'Poppins', marginTop:'0.3rem', fontSize: { xs: '0.875rem', sm: '1rem' } }}>
                  <PhoneIcon sx={{ marginRight: 1, fontSize: { xs: '1.25rem', sm: '1.5rem' } }} />
                  01-4168086, 4168106
                </Typography>
            </Box>
            <Box 
              sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'flex-start', 
                fontFamily: 'Poppins',
                paddingLeft:{xs:'5px',sm:"20px"},
                mt:{xs:"5px",sm:1}
              }}>
                <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', marginTop:'0.3rem', fontFamily: 'Poppins', fontSize: { xs: '0.875rem', sm: '1rem' } }}>
                  <ContactMailIcon sx={{ marginRight: 1, fontSize: { xs: '1.25rem', sm: '1.5rem' } }} />
                  info@educationpvt.com
                </Typography>
            </Box>
          </Grid>

          {/* Quick Links */}
          <Grid item xs={12} sm={6} md={4} sx={{ marginTop: '1rem', marginLeft: { md:'17rem'} }}>
            <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Poppins', fontSize: { xs: '1rem', sm: '1.25rem'},fontWeight:"600"}}>Quick Links</Typography>
            {footerNavItems.map((item) => (
              <Typography key={item.to} component={RouterLink} to={item.to} sx={{ textDecoration: 'none', color: '#ffffff', display: 'block', marginBottom: 1, fontFamily: 'Poppins', fontSize: { xs: '0.875rem', sm: '1rem' } }}>
                {item.name}
              </Typography>
            ))}
          </Grid>

          {/* Social Media */}
          <SocialMedia />
        </Grid>

        {/* Footer Credit */}
        <Grid>
          <Box sx={{ textAlign: 'center', marginTop: 3, marginBottom: 2, marginLeft: { xs: '-5rem', md: '10rem' } }}>
            <Typography variant="body2" sx={{ fontFamily: 'Poppins, sans-serif', fontSize: { xs: '0.75rem', sm: '0.875rem' },marginLeft:{xs:'5rem'} }}>&copy; All rights reserved.</Typography>
            <Typography variant="body2" sx={{ fontFamily: 'Poppins, sans-serif', fontSize: { xs: '0.75rem', sm: '0.875rem' },marginLeft:{xs:'5rem'}}}>
              Developed and Designed by <Link href="https://blackmoon.com.np/" target="_blank" color="inherit">Blackmoon</Link>
            </Typography>
          </Box>
        </Grid>
      </Container>
    </Box>
  );
};

export default FooterBar;
