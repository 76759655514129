import { Box, Typography, Container, IconButton} from '@mui/material';

import chooseUsData from './chooseUsData';
const ChooseUs = () =>{
    return(
        <Container maxWidth={false} sx={{
            backgroundColor: '#eaf2f8',
            padding: '3rem 0px',
            mt:5,
        }}>
            <Container
            >
                <section
                    style={{
                        // backgroundColor:"yellow",
                        display:"flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems:"center"
                    }}
                >
                    <Typography 
                        variant="h4" 
                        className="section-title" 
                        fontFamily="Roboto-Serif"
                        sx={{ 
                            fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' }, 
                            fontWeight: 'bold',
                            color: '#003366',
                            textTransform: 'uppercase',
                            textAlign: 'center',
                            marginBottom: { xs: '1.5rem', sm: '2rem' }
                        }}>
                        Why Choose Us?
                    </Typography>
                    
                    <Box 
                        className="icon-container" 
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            flexDirection: { xs: 'column', sm: 'row' },
                            justifyContent: 'space-around',
                            alignItems: 'center',
                            width: '100%',
                            maxWidth: '900px',
                            // backgroundColor:"red"
                        }}
                    >
                        {
                            chooseUsData.map((data,index)=>
                                <Box className="icon-box" sx={{ margin: {xs:'0.5rem',sm:'1.5rem'}, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <IconButton className="icon" sx={{ fontSize: '3rem', color: '#1F6F8E', marginBottom: '0.5rem' }} >
                                        <data.icon/>
                                    </IconButton>
                                    <Typography variant="h6"
                                        sx={{
                                            fontSize: { xs: '1rem', sm: '1.2rem'},
                                            fontFamily:"Poppins"
                                        }}
                                    >{data.chooseTitle}</Typography>
                                </Box>
                            
                            )
                        }
                        {/* Icon Boxes */}

                        
                    </Box>
                </section>
            </Container>
        </Container>
    )
}
export default ChooseUs;