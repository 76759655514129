import axios from 'axios';

const apiClient = axios.create({
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json',
    },
});

// Add interceptor to switch base URL
apiClient.interceptors.request.use(
    (config) => {
        if (window.location.hostname === 'localhost') {
            config.baseURL = 'http://localhost:8000/eduxpapiye'; // Local server
        } else {
            config.baseURL = 'https://educationpvt.com/eduxpapiye'; // Remote server
        }
        console.log('Axios Request Config:', config.baseURL);
        return config;
    },
    (error) => {
        console.error('Request Error:', error);
        return Promise.reject(error);
    }
);

// Add response interceptor
apiClient.interceptors.response.use(
    (response) => {
        // console.log('Response:', response);
        return response;
    },
    (error) => {
        console.error('Response Error:', {
            message: error.message,
            response: error.response,
            config: error.config,
        });
        return Promise.reject(error);
    }
);

export default apiClient;