
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Container, Grid } from '@mui/material';
import apiClient from '../../../config/axiosConfig';
import { Buffer } from 'buffer';
import Header from './Header';           
import ActionAreaCard from './country_card';  
import PaginationComponent from './paginationComponent'; 

function DestinedCountry() {
  const [page, setPage] = useState(1);
  const [allCountries, setAllCountries] = useState([]);
  const itemsPerPage = 3;

  useEffect(() => {
    const fetchAllCountries = () => {
      apiClient
        .get(`/destinedCountry`)
        .then((response) => {
          console.log('Country:', response);
          setAllCountries(response.data.CountryDetail);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchAllCountries();
  }, []);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const countriesToShow = allCountries.slice(startIndex, endIndex);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column',width:'85%',height:'80%',alignItems: 'center', py: 4 }}>
      <Container sx={{ }}>
        {/* Header Section */}
        <Header />

        {/* Country Cards Section */}
        <Box sx={{ mb: 4 }}>
          <Grid container spacing={8} justifyContent="center">
            {countriesToShow.map((country) => (
              <Grid
                item
                xs={12}  // 1 card per row on extra-small screens (100%)
                sm={6}   // 2 cards per row on small screens (50%)
                md={4}   // 3 cards per row on medium screens (33.33%)
                key={country.id}
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                <Link
                  to={`/country-detail/${country.id}`}
                  state={country}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  <ActionAreaCard
                    image={`data:image/${country.imageFormat};base64,${Buffer.from(country.flag).toString('base64')}`}
                    title={country.countryName}
                    description={`Explore higher education opportunities in ${country.countryName}`}
                  />
                </Link>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Pagination Section */}
        <PaginationComponent
          count={Math.ceil(allCountries.length / itemsPerPage)}
          page={page}
          onPageChange={handlePageChange}
        />
      </Container>
    </Box>
  );
}

export default DestinedCountry;
