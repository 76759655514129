import React from 'react';
import { Card, CardActionArea, CardContent, CardMedia, Box, Typography } from '@mui/material';

function CountryCard({ image, title, description }) {
  return (
    <Box >
      <Card
        sx={{
          display: 'flex',
          // backgroundColor:'blue',
          flexDirection: 'column',
          height: 'auto',
          minHeight: '380px',  // Ensure a consistent minimum height for all cards
          width: { xs: '100%', sm: '95%', md: '97%', lg: '110%' }, // Adjust width based on screen size
          borderRadius: '12px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', 
          transition: 'transform 0.3s, box-shadow 0.3s', 
          '&:hover': {
            transform: 'scale(1.05)', 
            boxShadow: '0 8px 20px rgba(0, 0, 0, 0.15)',
          },
          margin: '16px',
        }}
      >
        <CardActionArea sx={{ flex: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CardMedia
              component="img"
              height="200"
              image={image}
              alt={title}
            />
          </Box>
          <Box sx={{ padding: 2 }}>
            <CardContent sx={{ flex: 1 }}>
              <Typography gutterBottom variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
                {title}
              </Typography>
              <Typography 
                variant="body2" 
                sx={{ 
                  color: 'text.secondary', 
                  display: '-webkit-box', 
                  WebkitLineClamp: 3,  // Limit description to 3 lines
                  WebkitBoxOrient: 'vertical', 
                  overflow: 'hidden', 
                  textOverflow: 'ellipsis' // Truncate overflow text with ellipsis
                }}
              >
                {description}
              </Typography>
            </CardContent>
          </Box>
        </CardActionArea>
      </Card>
    </Box>
  );
}

export default CountryCard;
