import React, { useState, useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import ApplyFormDialog from './applyForm';
import logo from '../../../assets/eduLogo.png';
// import StorkeImage from '../../../assets/student image.jpg';
import StorkeImage from '../../../assets/test.jpg'
import SocialMedia from './social_media';
import EventDialog from './event_dialog';  
import apiClient from '../../../config/axiosConfig';

const HeaderSection = () => {
  const [dialogOpen, setDialogOpen] = useState(false); // State for dialog visibility
  const [eventDialogOpen, setEventDialogOpen] = useState(false); // State for the event dialog
  const [sliderValue, setSliderValue] = useState(0); // State to control slider value
  const [fetchEventsFrom, setFetchEvents] = useState([]); // State to store events data

  // Fetch events data
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await apiClient.get('/events');
        if (response.data.eventsDetail && response.data.eventsDetail.length > 0) {
          setFetchEvents(response.data.eventsDetail); // Update the events data
        }
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

    fetchEvents();
  }, []); // Empty dependency array to run once on mount

  // Automatically open the event dialog and set up auto-switch once events are fetched
  useEffect(() => {
    if (fetchEventsFrom.length > 0) {
      setEventDialogOpen(true); // Open the dialog once events are available

      // Auto-switch between events every 5 seconds
      const autoplayInterval = setInterval(() => {
        setSliderValue((prev) => (prev + 1) % fetchEventsFrom.length); // Wrap slider value around
      }, 5000);

      return () => clearInterval(autoplayInterval); // Cleanup interval on unmount
    }
  }, [fetchEventsFrom]); // This effect depends on fetchEventsFrom

  // Functions to handle dialog opening and closing
  const handleOpenDialog = () => setDialogOpen(true);
  const handleCloseDialog = () => setDialogOpen(false);
  const handleEventDialogClose = () => setEventDialogOpen(false);

  const buttonCss = {
    fontSize: { xs: '0.9rem', sm: '1.2rem' },
    background: 'linear-gradient(90deg, #49BBBD 0%, #136CB5 100%)',
    fontWeight: 'bold',
    transition: "3s ease-in",
    borderRadius: "4px",
    fontFamily: "Roboto-Serif",
    letterSpacing: { xs: "1px", sm: "2px", md: "4px" },
    '&:hover': {
      transform: 'Scale(1.1)',
      background: 'linear-gradient(90deg, #136CB5 0%, #49BBBD 100%)',
    },
  };

  return (
    <Box sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box
        component="span"
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          marginBottom: '1rem',
          width: { xs: '100px', sm: '120px', md: '150px', lg: '180px' },
        }}
      >
        <img src={logo} alt="Logo" style={{ width: '100%', height: 'auto' }} />
      </Box>

      <Typography
        variant="h3"
        fontFamily="Roboto-serif"
        fontWeight="600"
        sx={{
          fontSize: { xs: '18px', sm: "28px", md: "38px", lg: "48px" },
          textTransform: "uppercase",
          backgroundImage: `url(${StorkeImage})`,
          backgroundPosition:"center",
          backgroundSize: "contain",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          WebkitTextStroke: "1px #00000090",
        }}
      >
        Welcome To Education.Com
      </Typography>

      {/* <Typography
        variant="h5"
        sx={{
          fontWeight: 'bold',
          color: 'linear-gradient(90deg, #49BBBD 0%, #136CB5 100%)',
          marginTop: '2rem',
        }}
      >
        🚧 Under Construction 🚧
      </Typography> */}

      {/* Pass data to the EventDialog */}
      <EventDialog
        open={eventDialogOpen}
        handleClose={handleEventDialogClose}
        selectedEvent={fetchEventsFrom} // Pass the events data
        sliderValue={sliderValue}
        setSliderValue={setSliderValue}
      />

      <Box sx={{ marginTop: '2rem' }}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: "2rem",
          marginBottom: '2rem',
          gap: { xs: '1rem', sm: '4rem', md: '5rem' },
          flexDirection: { xs: 'column', sm: 'row' },
        }}>
          <Link to="/study-in-nepal" style={{ textDecoration: 'none' }}>
            <Button variant="contained" size="medium" sx={buttonCss}>
              Study IN Nepal
            </Button>
          </Link>
          <Link to="/study-abroad" style={{ textDecoration: 'none' }}>
            <Button variant="contained" size="medium" sx={buttonCss}>
              Study Abroad
            </Button>
          </Link>
        </Box>
        <Button
          variant="outlined"
          size="small"
          onClick={handleOpenDialog}
          sx={{
            border: "1px solid #136CB5",
            color: "#000000",
            '&:hover': {
              color: "#136CB5"
            }
          }}
        >
          Apply Now
        </Button>
        <SocialMedia />
      </Box>

      <ApplyFormDialog open={dialogOpen} handleClose={handleCloseDialog} />
    </Box>
  );
};

export default HeaderSection;