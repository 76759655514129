import React, { useEffect, useState } from "react";
import { Box, Typography, Container, Card, CardContent, Avatar, Button, Stack } from "@mui/material";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary"; // Icon for Services
import VisibilityIcon from "@mui/icons-material/Visibility"; // Icon for Vision
import TaskIcon from "@mui/icons-material/Task"; // Icon for Mission
import GroupIcon from "@mui/icons-material/Group"; // Icon for Community
import FooterBar from "../../layout/footer_bar/footer_bar_main";
import SocialMediaBar from "../SocialMedia/socialMedia";
import apiClient from "../../config/axiosConfig";
import { Buffer } from "buffer";

function About() {
  const [showMore, setShowMore] = useState(false);
  const [member, setMember] = useState([]);

  useEffect(() => {
    const fetchTeamMember = () => {
      apiClient.get(`/teamMember`).then((response) => {
        console.log(response);
        setMember(response.data.TeamMember);
      }).catch((error) => {
        console.log(error);
      });
    };
    fetchTeamMember();
  }, []);

  const handleReadMore = () => {
    setShowMore(!showMore);
  };

  return (
    <Box>
      <Container width="100%">
        {/* Section Title */}
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          align="center"
          sx={{ fontWeight: "bold", color: "#003366", marginTop: '3rem' }}
        >
          About Us
        </Typography>
        <Typography variant="body1" align="center" color="textSecondary" component='p'>
          Welcome to Education.com, where we believe in empowering students to achieve their educational dreams.
          Our goal is to provide high-quality resources and support to students, teachers, and educational institutions globally.
        </Typography>

        {/* Mission Statement */}
        <Box sx={{ my: 6 }}>
          <Box sx={{
            display: "flex",
            flexDirection: { xs: "column-reverse", sm: "row" },
            gap: 1
          }}>
            <Box sx={{ flex: 1 }}>
              <Typography
                variant="h5"
                component="h2"
                gutterBottom
                sx={{ fontWeight: "bold", color: "#003366" }}
              >
                Introduction
              </Typography>
              <Typography variant="body1" color="textSecondary" paragraph>
                Education.com is a premier educational consultancy dedicated to guiding students and professionals in achieving their academic and career aspirations.
                {showMore && (
                  <>
                    {" "} With a mission to provide world-class counseling and support, Education.com specializes in helping students explore global education opportunities, navigate admission processes, and secure placements in top universities and colleges around the world.
                    At Education.com, we believe in empowering students with personalized advice tailored to their unique academic goals and interests. Our expert team provides a wide range of services, including guidance on selecting courses and institutions, assistance with application preparation, and support in acquiring necessary visas and scholarships.
                    We also offer comprehensive IELTS, TOEFL, and other standardized test preparation to ensure that students are fully equipped for success in their educational journey abroad.
                  </>
                )}
              </Typography>
              <Button variant="contained" onClick={handleReadMore} sx={{ backgroundColor: "#136CB5", color: "#fff" }}>
                {showMore ? "Read Less" : "Read More"}
              </Button>
            </Box>

            <Box sx={{ flex: 1 }}>
              <img
                src={require('../../assets/images.png')} // Ensure this path is correct
                alt="Promotional Flyers"
                sx={{ maxWidth: "100%", height: { xs: "200px", sm: "300px" } }}
              />
            </Box>
          </Box>
        </Box>

        {/* Core Values */}
        <Typography variant="h5" component="h2" sx={{ fontWeight: "bold", color: "#003366", }}>
          Our Core Values
        </Typography>
        <Stack direction="row" spacing={4} justifyContent="center" sx={{ flexWrap: 'wrap', marginTop: 2 }}>
          {/* Services */}
          <Card sx={{ width: 250, boxShadow: 3 }}>
            <CardContent sx={{ textAlign: "center" }}>
              <Avatar sx={{ bgcolor: "#136CB5", width: 60, height: 60, margin: "auto" }}>
                <LocalLibraryIcon />
              </Avatar>
              <Typography variant="h6" component="div" gutterBottom>
                Services
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Guidance on choosing the right country, university, and course based on individual preferences and career goals.
                Support throughout the application process, from filling out forms to submitting required documents and meeting deadlines.
                Help in identifying and applying for scholarships, grants, and other financial aid options.
              </Typography>
            </CardContent>
          </Card>

          {/* Vision */}
          <Card sx={{ width: 250, boxShadow: 3 }}>
            <CardContent sx={{ textAlign: "center" }}>
              <Avatar sx={{ bgcolor: "#49BBBD", width: 60, height: 60, margin: "auto" }}>
                <VisibilityIcon />
              </Avatar>
              <Typography variant="h6" component="div" gutterBottom>
                Vision
              </Typography>
              <Typography variant="body2" color="textSecondary">
                To become the leading global educational consultancy that transforms lives by connecting students to world-class academic institutions and opportunities. We envision a future where every student, regardless of background, can pursue their dreams of international education with confidence and success.
              </Typography>
            </CardContent>
          </Card>

          {/* Mission */}
          <Card sx={{ width: 250, boxShadow: 3 }}>
            <CardContent sx={{ textAlign: "center" }}>
              <Avatar sx={{ bgcolor: "#136CB5", width: 60, height: 60, margin: "auto" }}>
                <TaskIcon />
              </Avatar>
              <Typography variant="h6" component="div" gutterBottom>
                Mission
              </Typography>
              <Typography variant="body2" color="textSecondary">
                To become the leading global educational consultancy that transforms lives by connecting students to world-class academic institutions and opportunities. We envision a future where every student, regardless of background, can pursue their dreams of international education with confidence and success.
              </Typography>
            </CardContent>
          </Card>

          {/* Community */}
          <Card sx={{ width: 250, boxShadow: 3 }}>
            <CardContent sx={{ textAlign: "center" }}>
              <Avatar sx={{ bgcolor: "#49BBBD", width: 60, height: 60, margin: "auto" }}>
                <GroupIcon />
              </Avatar>
              <Typography variant="h6" component="div" gutterBottom>
                Community
              </Typography>
              <Typography variant="body2" color="textSecondary">
                At Education.com, we believe that a strong community is the backbone of successful educational journeys. Our community is built on the foundation of collaboration, support, and shared experiences. We strive to connect students, educators, and institutions to create a nurturing environment where everyone can thrive.
              </Typography>
            </CardContent>
          </Card>
        </Stack>

        {/* New Members Section */}
        <Typography variant="h5" component="h2" gutterBottom align="center" sx={{ fontWeight: "bold", mt: 4, color: "#003366" }}>
          Meet Our Members
        </Typography>
        <Typography variant="body1" align="center" color="textSecondary" paragraph>
          Our members are at the heart of our community, contributing to a diverse and supportive environment.
          Here, we celebrate their achievements, experiences, and the unique perspectives they bring to our educational journeys.
        </Typography>

        <Stack direction="row" spacing={4} justifyContent="center" sx={{ flexWrap: 'wrap' }}>
          {/* Member Cards */}
          {member.map((mem, index) => (
            <Card sx={{ width: 250, boxShadow: 3 }} key={index}>
              <CardContent sx={{ textAlign: "center" }}>
                <Avatar src={`data:image/${mem.imageFormat};base64,${Buffer.from(mem.member_photo).toString('base64')}`} alt={mem.member_name} sx={{ width: 80, height: 80, margin: "auto" }} />
                <Typography variant="h6" component="div" gutterBottom sx={{ textTransform: "capitalize" }}>
                  {mem.member_name}
                </Typography>
                <Typography variant="body2" color="textSecondary" sx={{ textTransform: "capitalize" }}>
                  {mem.desig}
                </Typography>
                <Typography variant="body2" color="textSecondary" sx={{ textTransform: "capitalize" }}>
                  {mem.member_words}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Stack>
      </Container>
      <SocialMediaBar/>
      <FooterBar />
    </Box>
  );
}

export default About;
