import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Container,
  Card,
  CardContent,
  Avatar,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Buffer } from 'buffer';
import SocialMediaBar from '../SocialMedia/socialMedia';
import FooterBar from '../../layout/footer_bar/footer_bar_main';
import NepaliDate from 'nepali-date';  // Nepali date library

const bufferToBase64 = (buffer) => {
  if (!buffer) return '';
  return `data:image/jpeg;base64,${Buffer.from(buffer).toString('base64')}`;
};

function AlumniStudentWithRank({ studentRecord }) {
  const [view, setView] = useState('Study In Nepal'); // Default view set to 'Study In Nepal'
  const [filteredAlumni, setFilteredAlumni] = useState(studentRecord);
  const [selectedYear, setSelectedYear] = useState('');
  const [bsYears, setBsYears] = useState([]);

  // Convert admission date to BS format
  const convertToBS = (date) => {
    const nepaliDate = new NepaliDate(date);  // Convert the Gregorian date to BS
    return nepaliDate.format('YYYY-MM-DD');  // Returns BS formatted date
  };

  // Extract BS year from the date (used for filtering)
  const extractBSYear = (date) => {
    const bsDate = convertToBS(date);
    return bsDate.split('-')[0];  // Return only the year part of the BS date
  };

  // Get distinct BS years from the student records
  const getDistinctBSYears = () => {
    const years = studentRecord.map((alumni) => extractBSYear(alumni.admission_on));
    return [...new Set(years)];  // Get unique years
  };

  // Filter alumni by selected BS year
  const handleYearChange = (event) => {
    const selectedYear = event.target.value;
    setSelectedYear(selectedYear);

    const filtered = studentRecord.filter((alumni) => {
      const bsYear = extractBSYear(alumni.admission_on);
      return selectedYear === '' || bsYear === selectedYear; // Filter based on the selected BS year
    });

    setFilteredAlumni(filtered); // Update the filtered alumni
  };

  // Render alumni cards with additional details
  const renderAlumniCards = (alumniList) => (
    <Grid container spacing={2}> {/* Reduced spacing for more compact cards */}
      {alumniList.map((alumni) => (
        <Grid item xs={12} sm={6} md={4} key={alumni.sid}>
          <Card
            sx={{
              cursor: 'pointer',
              transition: 'transform 0.3s',
              '&:hover': { transform: 'scale(1.05)' },
              width: '100%', // Ensures it takes full available width within grid
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between', // Ensures space between items for a neat layout
              padding: '16px',
              marginTop: '1.5rem', // Reduced margin top
              boxShadow: 3,
              height: 'auto', // Allows dynamic height based on content
              '@media (max-width: 600px)': {
                height: 'auto',  // Allow auto height for small screens (mobiles)
              }
            }}
          >
            <CardContent sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <Avatar
                src={alumni.s_photo && alumni.s_photo.data ? bufferToBase64(alumni.s_photo.data) : ''}
                alt={alumni.s_name}
                sx={{
                  width: { xs: '60px', sm: '80px' },  // Adjust size based on screen size
                  height: { xs: '60px', sm: '80px' },
                  mx: 'auto',
                  mb: 2,
                }}
              />
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 'bold',
                  marginBottom: '8px',
                  fontSize: { xs: '1rem', sm: '1.2rem' },  // Font size scales with screen size
                }}
              >
                {alumni.s_name}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  marginBottom: '8px',
                  fontSize: { xs: '0.9rem', sm: '1rem' },  // Responsive font size
                }}
              >
                Program: {alumni.program}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  marginBottom: '8px',
                  fontSize: { xs: '0.9rem', sm: '1rem' },
                }}
              >
                Graduated College: {alumni.graduated_college || 'N/A'}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  marginBottom: '8px',
                  fontSize: { xs: '0.9rem', sm: '1rem' },
                }}
              >
                Admitted College: {alumni.admitted_college || 'N/A'}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );

  useEffect(() => {
    // Log the fetched data when `studentRecord` is updated (or initially received)
    console.log('Fetched Student Record:', studentRecord);

    // Set BS years for the dropdown
    setBsYears(getDistinctBSYears());
  }, [studentRecord]);

  return (
    <Box sx={{ background: 'linear-gradient(135deg, #f0f0f0, #dcdcdc)', minHeight: '100vh', color: 'black' }}>
      <Container maxWidth="100%" sx={{ paddingTop: '4rem', paddingBottom: '3rem' }}>
        <Box sx={{ textAlign: 'center', mb: 4 }}>
          <Button
            variant={view === 'Study In Nepal' ? 'contained' : 'outlined'}
            onClick={() => setView('Study In Nepal')}
            sx={{
              fontSize: { xs: '0.75rem', sm: '1rem' },  // Smaller font on mobile
              padding: { xs: '8px 16px', sm: '10px 20px' },  // Adjust padding for smaller screens
              margin: { xs: '5px', sm: '10px' },
            }}
          >
            Study In Nepal
          </Button>
          <Button
            variant={view === 'Study Abroad' ? 'contained' : 'outlined'}
            onClick={() => setView('Study Abroad')}
            sx={{
              fontSize: { xs: '0.75rem', sm: '1rem' },
              padding: { xs: '8px 16px', sm: '10px 20px' },
              margin: { xs: '5px', sm: '10px' },
            }}
          >
            Study Abroad
          </Button>
        </Box>

        <Typography variant="h3" align="center" sx={{ fontWeight: 'bold', marginBottom: '2rem' }}>
          Alumni of {view}
        </Typography>

        {/* BS Year Dropdown */}
        <FormControl sx={{ minWidth: 120 }} size="small">
          <InputLabel>Year</InputLabel>
          <Select
            value={selectedYear}
            onChange={handleYearChange}
            label="Year"
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            {/* Dynamically populate BS years from the alumni data */}
            {bsYears.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Filtered alumni list based on the selected view */}
        {view === 'Study In Nepal' && renderAlumniCards(filteredAlumni.filter(alumni => alumni.destination === 'Study Nepal'))}
        {view === 'Study Abroad' && renderAlumniCards(filteredAlumni.filter(alumni => alumni.destination === 'Study Abroad'))}
      </Container>
      <FooterBar />
      <SocialMediaBar />
    </Box>
  );
}

export default AlumniStudentWithRank;
