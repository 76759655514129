import React from 'react';
import { Button } from '@mui/material';

const ShowMoreButton = ({ visibleCount, totalTestimonials, onShowMore, onShowLess }) => {
  return visibleCount < totalTestimonials ? (
    <Button variant="contained" color="primary" onClick={onShowMore} 
            sx={{ 
                mt: 2, 
                backgroundColor: '#49BBBD',
                 color: '#ffffff' ,
                
                 }}>
                 Show More
    </Button>
  ) : (
    <Button variant="outlined" color="secondary" onClick={onShowLess} sx={{ mt: 2 }}>
      Show Less
    </Button>
  );
};

export default ShowMoreButton;
