import React from 'react';
import {Container } from '@mui/material';
import NewsSection from './newsSection';
import NoticeSection from './noticeSection';

function Information() {
  
  return (
    <Container maxWidth={false}
    
    sx={{ padding: '3rem 0px', backgroundColor: '#eaf2f8' }}>
        <Container
          sx={{
            // backgroundColor:"red",
            display:"flex",
            justifyContent:"space-between",
            flexDirection:{xs:"column",md:"row"},
            padding: 0
          }}
        > 
            <NoticeSection />
            <NewsSection />
      </Container>
    </Container>
  );
}

export default Information;
