import React, { useState, useEffect } from 'react';
import { Typography, IconButton, Drawer, List, ListItem, ListItemText, Box, Menu, MenuItem, Select, MenuItem as SelectMenuItem, FormControl, InputLabel } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../../assets/eduLogo.png';

const navItems = [
  { name: "Home", to: "/" },
  { name: "Study in Nepal", to: "/study-in-nepal" },
  { name: "Study Abroad", to: "/study-abroad" },
  { name: "About", to: "/about" },
  { name: "Alumni", to: "/alumni" },
  { name: "Contact", to: "/contact" },
];

function Navbar() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [selectedCourse, setSelectedCourse] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  const toggleDrawer = () => {
    setDrawerOpen((prev) => !prev);
  };

  const handleCourseChange = (event) => {
    setSelectedCourse(event.target.value);
    const selectedCourse = prepareCourses.find(course => course.name === event.target.value);
    if (selectedCourse) {
      navigate(selectedCourse.to);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        setShowNavbar(true);
      } else {
        setShowNavbar(false);
      }
      setLastScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  return (
    <Box component="nav" sx={{
      position: 'sticky',
      top: showNavbar ? 0 : '-70px',
      zIndex: 1000,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '30px',
      background: 'linear-gradient(90deg, #49BBBD 0%, #136CB5 100%)',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
      transition: 'top 0.3s',
      animation: showNavbar ? 'slideDown 0.5s ease' : '',
    }}>
      {/* Top Contact Info Section (aligned with the logo) */}
      <Box sx={{
        position: 'absolute',
        top: 10,
        right: 850,
        display: { xs: 'none', sm: 'flex' },
        gap: '15px',
        alignItems: 'center',
        color: '#fff',
        marginRight:'-40rem'
      }}>
        <Typography variant="body2">
          <a href="tel:01-4168086,4168106" style={{ color: '#fff', textDecoration: 'none', }}>Call: 01-4168086,4168106</a>
        </Typography>
        <Typography variant="body2">
          <a href="mailto:education.com4@gmail.com" style={{ color: '#fff', textDecoration: 'none', }}>Email: education.com4@gmail.com</a>
        </Typography>
      </Box>

      {/* Logo Section */}
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        marginLeft: { xs: '0.8rem', md: '3rem' },
        backgroundColor: '#ffffff',
        borderRadius: '50%',
        width: { xs: '60px', sm: '60px' },
        height: { xs: '55px', sm: '60px' },
      }}>
        <Link to="/" style={{ textDecoration: 'none' }}>
          <img
            src={logo}
            alt="Logo"
            style={{
              height: '100%',
              width: '100%',
            }}
          />
        </Link>
      </Box>

      {/* Desktop Navigation */}
      <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: '30px', marginRight: '1.5rem', alignItems: "center" }}>
        {navItems.map((item) => (
          <Typography
            key={item.to}
            sx={{
              fontSize: { xs: '14px', sm: '16px', md: '18px' },
              color: location.pathname === item.to ? '#E3B81E' : '#ffffff',
              fontWeight: 'bold',
              cursor: 'pointer',
              transition: 'color 0.3s, transform 0.3s',
              '&:hover': { color: '#E3B81E', transform: 'scale(1.1)' },
              padding: '5px 10px',
              borderRadius: '5px',
            }}
          >
            <Link to={item.to} style={{ color: 'inherit', textDecoration: 'none' }}>
              {item.name}
            </Link>
          </Typography>
        ))}
      </Box>

      {/* Mobile Drawer */}
      <IconButton
        onClick={toggleDrawer}
        sx={{
          display: { xs: 'block', md: 'none' },
          color: '#fff',
          backgroundColor: '#136CB5',
          '&:hover': { backgroundColor: '#49BBBD' },
          transition: 'background-color 0.3s ease',
          marginRight: '10px',
        }}
      >
        <MenuIcon />
      </IconButton>
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer}>
        <Box sx={{
          width: '240px',
          background: 'linear-gradient(90deg, #49BBBD 0%, #136CB5 100%)',
          height: '100%',
          color: '#fff',
          padding: '20px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        }}>
          <IconButton onClick={toggleDrawer} sx={{ color: '#fff', marginBottom: '20px' }}>
            <CloseIcon />
          </IconButton>
          <List>
            {navItems.map((item) => (
              <ListItem
                button
                key={item.to}
                onClick={toggleDrawer}
                sx={{
                  color: location.pathname === item.to ? '#E3B81E' : '#fff',
                  '&:hover': { backgroundColor: '#1B87A3' },
                }}
              >
                <Link to={item.to} style={{ color: 'inherit', textDecoration: 'none' }}>
                  <ListItemText primary={item.name} />
                </Link>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </Box>
  );
}

export default Navbar;






