import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Container,
  Card,
  CardContent,
  Avatar,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { Buffer } from 'buffer';
import SocialMediaBar from '../SocialMedia/socialMedia';
import FooterBar from '../../layout/footer_bar/footer_bar_main';
import NepaliDate from 'nepali-date';  // Nepali date library

const bufferToBase64 = (buffer) => {
  if (!buffer) return '';
  return `data:image/jpeg;base64,${Buffer.from(buffer).toString('base64')}`;
};

function AlumniStudentWithRank({ studentRecord }) {
  const [view, setView] = useState('Study In Nepal'); // Default view set to 'Study In Nepal'
  const [filteredAlumni, setFilteredAlumni] = useState(studentRecord);
  const [selectedYear, setSelectedYear] = useState('');
  const [bsYears, setBsYears] = useState([]);
  const [visibleAlumniCount, setVisibleAlumniCount] = useState(12); // Initially show 12 alumni
  const [openDialog, setOpenDialog] = useState(false); // Dialog state
  const [selectedAlumni, setSelectedAlumni] = useState(null); // To store selected alumni for the dialog

  // Convert admission date to BS format
  const convertToBS = (date) => {
    const nepaliDate = new NepaliDate(date);  // Convert the Gregorian date to BS
    return nepaliDate.format('YYYY-MM-DD');  // Returns BS formatted date
  };

  // Extract BS year from the date (used for filtering)
  const extractBSYear = (date) => {
    const bsDate = convertToBS(date);
    return bsDate.split('-')[0];  // Return only the year part of the BS date
  };

  // Get distinct BS years from the student records
  const getDistinctBSYears = () => {
    const years = studentRecord.map((alumni) => extractBSYear(alumni.admission_on));
    return [...new Set(years)];  // Get unique years
  };

  // Filter alumni by selected BS year
  const handleYearChange = (event) => {
    const selectedYear = event.target.value;
    setSelectedYear(selectedYear);

    const filtered = studentRecord.filter((alumni) => {
      const bsYear = extractBSYear(alumni.admission_on);
      return selectedYear === '' || bsYear === selectedYear; // Filter based on the selected BS year
    });

    setFilteredAlumni(filtered); // Update the filtered alumni
  };

  // Open dialog with the selected alumni details
  const handleCardClick = (alumni) => {
    setSelectedAlumni(alumni);
    setOpenDialog(true);
  };

  // Close the dialog
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedAlumni(null);
  };

  // Render alumni cards with additional details
  const renderAlumniCards = (alumniList) => (
    <Grid container spacing={6} justifyContent="center" alignItems="center">
      {alumniList.slice(0, visibleAlumniCount).map((alumni) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={alumni.sid}>
          <Card
            sx={{
              cursor: 'pointer',
              transition: 'transform 0.3s',
              '&:hover': { transform: 'scale(1.05)' },
              // width: '100%',  
              maxWidth: { xs: 280, sm: 300, md: 320, lg: 400 }, // Adjust card width for different screen sizes
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: { xs: '12px', sm: '16px' },  // Adjust padding based on screen size
              boxShadow: 3,
              height: 'auto',
              borderRadius: 2,
              textAlign: 'center',  // Center the text inside card
            }}
            onClick={() => handleCardClick(alumni)} // On click, open dialog with alumni details
          >
            <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <Avatar
                src={alumni.s_photo && alumni.s_photo.data ? bufferToBase64(alumni.s_photo.data) : ''}
                alt={alumni.s_name}
                sx={{
                  width: { xs: 60, sm: 80, md: 100 }, // Image size adjusts based on screen size
                  height: { xs: 60, sm: 80, md: 100 },
                  mb: 2,
                }}
              />
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 'bold',
                  marginBottom: { xs: '8px', sm: '12px' }, // Adjust margin for small screens
                  fontSize: { xs: '1rem', sm: '1.2rem' }, // Adjust font size for small screens
                }}
              >
                {alumni.s_name}
              </Typography>

              {/* Display the Program */}
              {alumni.program && (
                <Typography variant="body2" sx={{ fontSize: { xs: '0.9rem', sm: '1rem' }, color: 'gray', mt: 1 }}>
                  Program: {alumni.program}
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );

  // Load more alumni when the button is clicked
  const loadMoreAlumni = () => {
    setVisibleAlumniCount((prevCount) => prevCount + 12); // Show 12 more alumni
  };

  useEffect(() => {
    // Log the fetched data when studentRecord is updated (or initially received)
    console.log('Fetched Student Record:', studentRecord);

    // Set BS years for the dropdown
    setBsYears(getDistinctBSYears());
  }, [studentRecord]);

  return (
    <Box sx={{ background: 'linear-gradient(135deg, #f0f0f0, #dcdcdc)', minHeight: '100vh', color: 'black', display: 'flex', flexDirection: 'column' }}>
      {/* Container that wraps the content */}
      <Container maxWidth="xl" sx={{ flex: 1, paddingTop: '4rem', paddingBottom: '3rem' }}>
        <Box sx={{ textAlign: 'center', mb: 4 }}>
          <Button
            variant={view === 'Study In Nepal' ? 'contained' : 'outlined'}
            onClick={() => setView('Study In Nepal')}
            sx={{
              fontSize: { xs: '0.75rem', sm: '1rem' },
              padding: { xs: '8px 16px', sm: '10px 20px' },
              margin: { xs: '5px', sm: '10px' },
            }}
          >
            Study In Nepal
          </Button>
          <Button
            variant={view === 'Study Abroad' ? 'contained' : 'outlined'}
            onClick={() => setView('Study Abroad')}
            sx={{
              fontSize: { xs: '0.75rem', sm: '1rem' },
              padding: { xs: '8px 16px', sm: '10px 20px' },
              margin: { xs: '5px', sm: '10px' },
            }}
          >
            Study Abroad
          </Button>
        </Box>

        <Typography variant="h3" align="center" sx={{ fontWeight: 'bold', marginBottom: '2rem' }}>
          Alumni of {view}
        </Typography>

        {/* BS Year Dropdown */}
        <FormControl sx={{ minWidth: 120 }} size="small">
          <InputLabel>Year</InputLabel>
          <Select value={selectedYear} onChange={handleYearChange} label="Year">
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            {bsYears.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Filtered alumni list based on the selected view */}
        {view === 'Study In Nepal' && renderAlumniCards(filteredAlumni.filter((alumni) => alumni.destination === 'Study Nepal'))}
        {view === 'Study Abroad' && renderAlumniCards(filteredAlumni.filter((alumni) => alumni.destination === 'Study Abroad'))}

        {/* Load More Button */}
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Button
            variant="contained"
            onClick={loadMoreAlumni}
            sx={{ fontSize: '1rem', padding: '10px 20px' }}
          >
            Load More
          </Button>
        </Box>
      </Container>

      {/* Dialog for overflow text */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{selectedAlumni?.s_name}</DialogTitle>
        <DialogContent>
          <Typography variant="body2">Program: {selectedAlumni?.program}</Typography>
          <Typography variant="body2">Graduated College: {selectedAlumni?.graduated_college || 'N/A'}</Typography>
          <Typography variant="body2">Admitted College: {selectedAlumni?.admitted_college || 'N/A'}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>

      <FooterBar />
      <SocialMediaBar />
    </Box>
  );
}

export default AlumniStudentWithRank;
