import React from "react";
import Grid from '@mui/material/Grid2';
import {Box,Typography,Link} from '@mui/material';
import {
    Facebook as FacebookIcon,
    Instagram as InstagramIcon,
    YouTube as YouTubeIcon,
    LinkedIn as LinkedInIcon
  } from '@mui/icons-material';
const SocialMedia = ()=>{
    return(
        <Grid item xs={12} sm={6} md={4} sx={{ marginLeft: {md:'auto'}, marginTop: '1rem' }}>
            <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Poppins', fontSize: { xs: '1rem', sm: '1.25rem' },fontWeight:"600" }}>Follow us on</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Link href="https://www.facebook.com/educationdotcom" target="_blank" color="inherit" sx={{ display: 'flex', alignItems: 'center', marginBottom: 1,textDecoration:"none"}}>
                    <FacebookIcon sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem' } }} /> 
                    <Typography variant="body1" sx={{ ml: 1, fontFamily: 'Poppins', fontSize: { xs: '0.875rem', sm: '1rem' } }}>Facebook</Typography>
                </Link>
                <Link href="https://www.instagram.com/education.com2009/" target="_blank" color="inherit" sx={{ display: 'flex', alignItems: 'center', marginBottom: 1,textDecoration:"none" }}>
                  
                    <InstagramIcon sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem' } }} /> 
                    <Typography variant="body1" sx={{ ml: 1, fontFamily: 'Poppins', fontSize: { xs: '0.875rem', sm: '1rem' } }}>Instagram</Typography>
                </Link>
                <Link href="https://youtube.com" target="_blank" color="inherit" sx={{ display: 'flex', alignItems: 'center', marginBottom: 1,textDecoration:"none" }}>
                    <YouTubeIcon sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem' } }} /> 
                    <Typography variant="body1" sx={{ ml: 1, fontFamily: 'Poppins', fontSize: { xs: '0.875rem', sm: '1rem' } }}>YouTube</Typography>
                </Link>
                <Link href="https://linkedin.com" target="_blank" color="inherit" sx={{ display: 'flex', alignItems: 'center', marginBottom: 1,textDecoration:"none" }}>
                    <LinkedInIcon sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem' } }} /> 
                    <Typography variant="body1" sx={{ ml: 1, fontFamily: 'Poppins', fontSize: { xs: '0.875rem', sm: '1rem' } }}>LinkedIn</Typography>
                </Link>
                </Box>
        </Grid>
    )
}
export default SocialMedia;