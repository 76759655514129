
import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Container, Paper, Stack } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import FooterBar from '../../layout/footer_bar/footer_bar_main';
import apiClient from '../../config/axiosConfig';
import { toast } from 'react-toastify';
import SocialMediaBar from '../SocialMedia/socialMedia';

function Contact() {
    const [studentQuery, setStudentQuery] = useState({
        s_name: '',
        s_contact: '',
        s_message: '',
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(studentQuery);
        apiClient.post(`/studentQuery`, studentQuery).then((response) => {
            console.log(response);
            if (response.data.success) {
                toast.success("Thank you", {
                    position: "top-right",
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            } else {
                toast.error("Sorry, something went wrong.", {
                    position: "top-right",
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
        }).catch((error) => {
            console.log(error);
        });
        setStudentQuery({
            s_name: '',
            s_contact: '',
            s_message: '',
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setStudentQuery((prev) => ({ ...prev, [name]: value }));
    };

    return (
        <Box>
            <Container maxWidth="lg" sx={{ my: 5 }}>
                <Typography
                    variant="h4"
                    component="h1"
                    gutterBottom
                    align="center"
                    sx={{ fontWeight: 'bold', color: '#003366' }}
                >
                    Contact Us
                </Typography>
                {/* <Paper elevation={3} sx={{ p: 4 }}> */}
                    <Stack
                        direction={{ xs: 'column', md: 'row' }}
                        spacing={3}
                        alignItems="flex-start"
                        justifyContent="space-between"
                    >
                        {/* Form Section */}
                        <Box flex={1}>
                            <form onSubmit={handleSubmit}>
                                <Stack spacing={2}>
                                    <TextField
                                        required
                                        label="Name"
                                        fullWidth
                                        variant="outlined"
                                        name="s_name"
                                        value={studentQuery.s_name}
                                        onChange={handleChange}
                                    />
                                    <TextField
                                        required
                                        type="tel"
                                        label="Contact"
                                        fullWidth
                                        variant="outlined"
                                        name="s_contact"
                                        value={studentQuery.s_contact}
                                        onChange={handleChange}
                                    />
                                    <TextField
                                        required
                                        label="Message"
                                        fullWidth
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                        name="s_message"
                                        value={studentQuery.s_message}
                                        onChange={handleChange}
                                    />
                                    <Button type="submit" variant="contained" color="primary">
                                        Send Message
                                    </Button>
                                </Stack>
                            </form>
                        </Box>

                        {/* Map Section */}
                        <Box flex={1}>
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3532.677634116068!2d85.324!3d27.7172!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb191c29b0b3df%3A0xe295c0a12b3c5820!2sKathmandu%2C%20Nepal!5e0!3m2!1sen!2sus!4v1617306220567!5m2!1sen!2sus"
                                width="100%"
                                height="300"
                                style={{ border: 0 }}
                                allowFullScreen=""
                                loading="lazy"
                                title="Google Map of Kathmandu, Nepal"
                            ></iframe>
                        </Box>
                    </Stack>
                {/* </Paper> */}

                {/* Additional Sections */}
                <Typography
                    variant="h5"
                    component="h2"
                    gutterBottom
                    sx={{ fontWeight: 'bold', mt: 5, color: '#003366' }}
                >
                    Get in Touch
                </Typography>
                <Typography variant="body1" align="center" color="textSecondary">
                    Email: support@education.com
                </Typography>
                <Typography variant="body1" align="center" color="textSecondary">
                    Phone: +977 9812345786
                </Typography>

                {/* Social Media Section */}
                <Box>
                    <Typography
                        variant="h5"
                        component="h2"
                        gutterBottom
                        sx={{ fontWeight: 'bold', color: '#003366' }}
                    >
                        Follow Us
                    </Typography>
                    <Stack direction="row" spacing={2} justifyContent="center">
                        <a href="https://www.facebook.com/educationdotcom" target="_blank" rel="noopener noreferrer">
                            <FacebookIcon fontSize="large" sx={{ color: '#4267B2' }} />
                        </a>
                        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                            <TwitterIcon fontSize="large" sx={{ color: '#1DA1F2' }} />
                        </a>
                        <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
                            <LinkedInIcon fontSize="large" sx={{ color: '#0077B5' }} />
                        </a>
                        <a href="https://www.instagram.com/education.com2009/" target="_blank" rel="noopener noreferrer">
                            <InstagramIcon fontSize="large" sx={{ color: '#C13584' }} />
                        </a>
                    </Stack>
                </Box>
            </Container>
            <SocialMediaBar/>
            <FooterBar />
        </Box>
    );
}

export default Contact;
