import React from "react";
import { IconButton, Box, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

const IconContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '20px',
  marginTop: '10px',
}));

const SocialMedia = () => {
  return (
    <Box>
      <IconContainer sx={{ marginBottom: '2rem' }}>
        <Tooltip title="Facebook">
          <IconButton
            onClick={() => window.open("https://www.facebook.com/educationdotcom", "_blank")}
          >
            <FacebookIcon sx={{ color: '#3b5998' }} />
          </IconButton>
        </Tooltip>

        <Tooltip title="Instagram">
          <IconButton
            sx={{ fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' } }}
            onClick={() => window.open("https://www.instagram.com/education.com2009/", "_blank")}
          >
            <InstagramIcon sx={{ color: '#C13584' }} />
          </IconButton>
        </Tooltip>
      </IconContainer>
    </Box>
  );
};

export default SocialMedia;





