// import React, { useState } from 'react';
// import { Card,Box, CardContent, Typography, Avatar, Button, Container } from '@mui/material';
// import Grid from '@mui/material/Grid2';
// import './Testimonial.css';
// import { Buffer } from 'buffer';



// const Testimonials = ({studentTestimonial}) => {
//   // console.log("Nepali student testi:",studentTestimonial);
//   const [visibleCount, setVisibleCount] = useState(3); // Start with 3 testimonials visible

//   const handleShowMore = () => {
//     setVisibleCount(prevCount => prevCount + 3); // Increase count by 3
//   };

//   const handleShowLess = () => {
//     setVisibleCount(3); // Reset count to 3
//   };

//   return (
//     <Container>
//         <Box className="testimonials-container">
//             <Typography variant="h4" align="center" gutterBottom 
//               sx={{ 
//                 fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' }, 
//                 fontWeight: 'bold',
//                 // color: '#003366',
//                 backgroundColor:'Yellow',
//                 textTransform: 'uppercase', width: '100%' 
//               }}
//             >
//               Student Voice
//             </Typography>
//             <Grid container spacing={5} justifyContent="center">
//               {studentTestimonial.slice(0, visibleCount).map((testimonial, index) => (
//                 <Grid item xs={12} sm={6} md={4} key={index}
//                   sx={{
//                     // backgroundColor:"green",
//                   }}
//                 >

//                   <Card className="testimonial-card">
//                     <CardContent 
//                       sx={{
//                         // backgroundColor:"blue",
//                         display:"flex",
//                         flexDirection:"column",
//                         // justifyContent:"center",
//                         alignItems:"center"
//                       }}
//                     >
//                       {
//                         testimonial.photo_format !== null ?(
//                           <Avatar 
//                               src={`data:image/${testimonial.photo_format || 'jpeg'};base64,${Buffer.from(testimonial.s_photo).toString('base64')}`} 
//                               alt={testimonial.s_name} 
//                               sx={{
//                                 height:"80px",
//                                 width:"80px"
//                               }}
//                           />
//                         ):(<Avatar 
//                           src=''
//                           alt={testimonial.s_name} 
//                           sx={{
//                             height:"80px",
//                             width:"80px"
//                           }}
//                         />)
//                       }
//                       <Typography variant="body1" className="testimonial-text"
//                         sx={{
//                           fontFamily:"Poppins",
//                           fontSize: '1rem',
//                         }}
//                       >
//                         "{testimonial.feedback}"
//                       </Typography>
//                       <Typography variant="h6" className="testimonial-name"
//                         sx={{
//                           fontFamily:"Poppins",
//                           fontSize: '1rem',
//                         }}
//                       >
//                         {testimonial.s_name}
//                       </Typography>
//                       <Typography variant="subtitle2" className="testimonial-position"
//                         sx={{
//                           fontFamily:"Poppins",
//                           fontSize: '1rem',
//                         }}
//                       >
//                         {testimonial.admitted_college}
//                       </Typography>
//                     </CardContent>
//                   </Card>
//                 </Grid>
//               ))}
//             </Grid>
//             {visibleCount < studentTestimonial.length ? (
//               <Button variant="contained" color="primary" onClick={handleShowMore} sx={{ mt: 2 , backgroundColor: '#49BBBD',color:"#ffffff"}}>
//                 Show More
//               </Button>
//             ) : (
//               <Button variant="outlined" color="secondary" onClick={handleShowLess} sx={{ mt: 2 }}>
//                 Show Less
//               </Button>
//             )}
//         </Box>
//     </Container>
//   );
// };

// export default Testimonials;











import React, { useState } from 'react';
import { Box, Container, Grid } from '@mui/material';
import TestimonialCard from './testonomialCard'; 
import ShowMoreButton from './showMoreButton'; 
import TestimonialsHeader from './testonomialHeader'; 
import './Testimonial.css';

const Testimonials = ({ studentTestimonial }) => {
  const [visibleCount, setVisibleCount] = useState(3); // Start with 3 testimonials visible

  const handleShowMore = () => {
    setVisibleCount(prevCount => prevCount + 3); // Increase count by 3
  };

  const handleShowLess = () => {
    setVisibleCount(3); // Reset count to 3
  };

  return (
    <Container>
      <Box className="testimonials-container">
        {/* Header Component */}
        <TestimonialsHeader />

        {/* Testimonials Grid */}
        <Grid container spacing={5} justifyContent="center">
          {studentTestimonial.slice(0, visibleCount).map((testimonial, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <TestimonialCard testimonial={testimonial} /> {/* TestimonialCard component */}
            </Grid>
          ))}
        </Grid>

        {/* Show More / Show Less Button */}
        <ShowMoreButton
          visibleCount={visibleCount}
          totalTestimonials={studentTestimonial.length}
          onShowMore={handleShowMore}
          onShowLess={handleShowLess}
        />
      </Box>
    </Container>
  );
};

export default Testimonials;