import React, { useEffect, useState } from "react";
import { Box, Button, Modal, Backdrop, Fade, useMediaQuery } from "@mui/material";
import ReactPlayer from 'react-player';
import apiClient from "../../../../config/axiosConfig";
import ActionAreaCard from './card';

function Carousel() {
  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [selectedTab, setSelectedTab] = useState("image"); // Default selected tab is "image"
  const [video, setVideo] = useState([]);
  const [loadImage, setLoadImage] = useState([]);
  const [videoOrder, setVideoOrder] = useState(video);
  const [currentIndex, setCurrentIndex] = useState(0); // Track the current index for sliding

  // Use media query to determine screen size
  const isXs = useMediaQuery("(max-width:600px)");
  const isSm = useMediaQuery("(max-width:900px)");
  const isMd = useMediaQuery("(min-width:900px)");

  // Fetch images and videos
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [imageResponse, videoResponse] = await Promise.all([
          apiClient.get("/gallery"),
          apiClient.get("/videos"),
        ]);

        // Preprocess images to base64
        const base64Images = await Promise.all(imageResponse.data.GalleryDetail.map(async (image) => {
          const base64String = await getBase64Image(image.gallery_image);
          return {
            ...image,
            base64Image: base64String,
          };
        }));

        setLoadImage(base64Images);
        setVideo(videoResponse.data.videoDetail);
        setVideoOrder(videoResponse.data.videoDetail); // Set initial order for videos

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const handleNext = () => {
    const totalItems = selectedTab === 'image' ? loadImage.length : videoOrder.length;
    setCurrentIndex((prevIndex) => (prevIndex + 1) % totalItems); // Loop back to the first item when reaching the end
  };

  const handlePrev = () => {
    const totalItems = selectedTab === 'image' ? loadImage.length : videoOrder.length;
    setCurrentIndex((prevIndex) => (prevIndex - 1 + totalItems) % totalItems); // Loop back to the last item when reaching the start
  };

  const handleVideoClick = (videoLink) => {
    setModalContent({ type: "video", content: videoLink });
    setOpen(true);
    setSelectedTab("video"); // Set the selected tab to video
  };

  const handleClose = () => setOpen(false);

  const getBase64Image = (imageBuffer) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(new Blob([new Uint8Array(imageBuffer.data)]));
    });
  };

  // Determine the number of cards to display based on screen size
  const cardsPerPage = isXs ? 1 : isSm ? 3 : isMd ? 4 : 4;

  const generateDots = (totalItems) => {
    return Array.from({ length: totalItems }).map((_, index) => (
      <Box
        key={index}
        sx={{
          width: 12,
          height: 12,
          borderRadius: '50%',
          backgroundColor: currentIndex === index ? 'primary.main' : 'gray',
          margin: '0 5px',
          cursor: 'pointer',
          // backgroundColor:'pink'
        }}
        onClick={() => setCurrentIndex(index)}
      />
    ));
  };

  return (
    <Box sx={{ textAlign: "center",alignItems:'center',width:'97%',justifyContent:'center',mt: 2 }}>
      {/* Buttons for Image and Video Selection */}
      <Box sx={{ mb: 2,width:'100%',justifyContent:'center', }}>
        <Button
          variant="contained"
          color={selectedTab === "image" ? "success" : "primary"} // Green for selected tab
          onClick={() => {
            setSelectedTab("image");
          }}
          sx={{ mr: 2 ,}}
        >
          Image
        </Button>
        <Button
          variant="contained"
          color={selectedTab === "video" ? "success" : "primary"} // Green for selected tab
          onClick={() => {
            setSelectedTab("video");
          }}
        >
          Video
        </Button>
      </Box>

      {/* Carousel container */}
      {/* <Box sx={{ mb: 4 , backgroundColor:'red'}}> */}
        <Box sx={{ display: "flex",  justifyContent: "center",overflow: "hidden", alignItems: "center", mt: 2,width:'100%'}}>
          {/* <Box sx={{backgroundColor:'blue'}}> */}
            <Box
              sx={{
                // backgroundColor:'red',
                // width:'9',
                display: "flex",
                // marginRight:{xs:'-2rem'},
                transition: "transform 0.5s ease",
                transform: `translateX(-${(currentIndex * (100 / cardsPerPage))}%)`, // Adjust for number of cards
              }}
            >
              {/* Display images when 'image' tab is selected */}
              {selectedTab === "image" &&
                [...loadImage, ...loadImage].map((image, i) => (  // Duplicate the list for infinite scroll effect
                  <Box key={i} sx={{ flex: `0 0 ${100 / cardsPerPage}%`,padding: {sm: '0.5rem',lg:'1rem' }}}>
                    <ActionAreaCard
                      image={image.base64Image}  // Image passed dynamically
                      title={image.image_title}  // Title passed dynamically
                      description={image.image_description}  // Description passed dynamically
                    />
                  </Box>
                ))}

              {/* Display videos when 'video' tab is selected */}
              {selectedTab === "video" &&
                [...videoOrder, ...videoOrder].map((videoItem, i) => (  // Duplicate the list for infinite scroll effect
                  <Box key={i} sx={{ flex: `0 0 ${100 / cardsPerPage}%`, padding: {sm: '0.5rem',lg:'1rem' } }}>
                    <ActionAreaCard
                      image={`https://img.youtube.com/vi/${new URL(videoItem.video_link).searchParams.get('v')}/0.jpg`}  // Video thumbnail
                      title={videoItem.video_title}  // Title of the video
                      description={videoItem.video_description}  // Description of the video
                      onClick={() => handleVideoClick(videoItem.video_link)}  // Click event to open video
                    />
                  </Box>
                ))}
            </Box>
          {/* </Box> */}
        </Box>
      {/* </Box> */}

      {/* Dot navigation */}
      <Box sx={{ mb: 4, display: 'flex', justifyContent: 'center', mt: 2 }}>
        {selectedTab === "image" && generateDots(loadImage.length)}
        {selectedTab === "video" && generateDots(videoOrder.length)}
      </Box>

      {/* Modal for showing image or video */}
      <Box>
        <Modal
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'white',
                borderRadius: 2,
                boxShadow: 24,
                // borderRadius:'5px solid red',
                maxWidth: '70%',
                maxHeight: '80%',
                // overflow: 'auto',
              }}
            >
              {modalContent?.type === "image" ? (
                <img
                  src={modalContent.content} // Assuming the image URL for modal is passed
                  alt="Modal Content"
                  style={{
                    width: '100%',
                    objectFit: 'cover',
                  }}
                />
              ) : modalContent?.type === "video" ? (
                <ReactPlayer
                  url={modalContent.content} // Using video link directly from modal content
                  width="100%"
                  height="auto"
                  controls
                />
              ) : null}
            </Box>
          </Fade>
        </Modal>
      </Box>
    </Box>
  );
}

export default Carousel;
