import React, { useState } from 'react';
import { Box, Typography, Container, Card, CardContent } from '@mui/material';
import { Link } from 'react-router-dom';
import FooterBar from '../../layout/footer_bar/footer_bar_main';
import SecondSection from './Icon_Section';
import OurServices from '../section/services.jsx';
import Testimonial from '../section/Testonomial/Testimonial.jsx';
import Information from '../section/newsNotices';
import Videos from '../section/Media/Card/Videos.jsx';
import DestinedCountry from './Country/destinedCountry.jsx';
import SocialMediaBar from '../SocialMedia/socialMedia.jsx';
import { Buffer } from 'buffer';
import Slider from 'react-slick'; // Import the Slider component

function StudyAbroad({ studentTestimonial, abroadCourses }) {
  const [page, setPage] = useState(1);
  const itemsPerPage = 3;

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const coursesToShow = abroadCourses.slice(startIndex, endIndex);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 600,
    slidesToShow: 3, // 3 cards visible by default
    slidesToScroll: 1,
    centerMode: false,
    centerPadding: '10px',
    responsive: [
      {
        breakpoint: 1024, // For medium devices (tablets)
        settings: {
          slidesToShow: 2, // 2 cards visible on medium devices
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600, // For smaller devices (phones)
        settings: {
          slidesToShow: 1, // 1 card visible on smaller screens
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box
      sx={{
        backgroundColor: '#f5f5f5',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Container maxWidth="lg" >
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          align="center"
          sx={{
            fontWeight: 'bold',
            color: '#003366',
            mt: { xs: 4, md: 6 },
            fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
            fontFamily: 'Roboto-Serif',
            textTransform: 'uppercase',
          }}
        >
          Test Preparation
        </Typography>
        <Typography
          variant="body1"
          align="center"
          color="textSecondary"
          component="p"
          sx={{
            mb: { xs: 4, md: 6 },
            fontFamily: 'Poppins',
             textTransform: "capitalize"
          }}
        >
          Join Us Today - get 100% scholarship.
        </Typography>

        {/* Study Abroad Cards - Using Slider */}
        <Slider {...sliderSettings}>
          {coursesToShow.map((course) => (
            <div key={course.id}>
              <Card
                sx={{
                  // width: '100%',
                  height: 'auto', 
                  borderRadius: '12px',
                  margin: '10px 10px',
                  transition: 'transform 0.2s',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                  // boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                  margin: '10px 10px',
                }}
              >
                <Link
                  to={{ pathname: '/abroad-course-desc' }}
                  state={course}
                  style={{
                    textDecoration: 'none',
                    color: 'inherit',
                    height: '100%',
                  }}
                >
                  <CardContent
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      // padding: '16px',
                      // height: '100%',
                    }}
                  >
                    {/* Adjust image height and maintain aspect ratio */}
                    <Box
                      sx={{
                        width: '100%',
                        height: '210px', // Set image height as needed (same as Study in Nepal)
                        borderRadius: '12px',
                        marginBottom: '16px',
                        backgroundImage: `url(data:image/${course.imageFormat};base64,${Buffer.from(
                          course.courseImage
                        ).toString('base64')})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                      }}
                    />
                    <Typography
                      variant="h6"
                      gutterBottom
                      align="center"
                      sx={{
                        fontWeight: 'bold',
                        fontSize: { xs: '14px', sm: '16px', md: '20px' },
                        fontFamily: 'Roboto-Serif',
                      }}
                    >
                      {course.courseTitle}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      align="center"
                      sx={{
                        fontSize: { xs: '12px', sm: '14px', md: '16px' },
                        fontFamily: 'Poppins',
                      }}
                    >
                      {course.overview.split(' ').slice(0, 10).join(' ')}
                      {course.overview.split(' ').length > 30 ? '...' : ''}
                    </Typography>
                  </CardContent>
                </Link>
              </Card>
            </div>
          ))}
        </Slider>

        {/* Pagination - Optional if you want to manually paginate */}
        {/* <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <Pagination
            count={Math.ceil(abroadCourses.length / itemsPerPage)}
            page={page}
            onChange={handlePageChange}
            color="primary"
          />
        </Box> */}
      </Container>

      <SecondSection />
      <DestinedCountry />
      <OurServices serviceRequested={'Study Abroad'} />
      <Testimonial studentTestimonial={studentTestimonial} />
      <Information />
      <Videos />
      <SocialMediaBar />
      <FooterBar />
    </Box>
  );
}

export default StudyAbroad;
