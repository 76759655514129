import React from 'react';
import { Pagination, Box } from '@mui/material';

function PaginationComponent({ count, page, onPageChange }) {
  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <Pagination
        count={count}
        page={page}
        onChange={onPageChange}
        color="primary"
        sx={{ mt: 4 }}
      />
    </Box>
  );
}

export default PaginationComponent;
