import React from 'react';
import {Container,Box} from '@mui/material';
import HeaderSection from './first_page_component/header_section';
import ReactPlayer from 'react-player';


const Firstpage = () => {
  
  return (
    <Container
      maxWidth={false}
      sx={{
        position:"relative",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: "100vh",
        // backgroundColor:'red',
      }}
    >
      <Box
        sx={{
          position:"absolute",
          width:"100%",
          height:"100vh",
        }}
      >
        <ReactPlayer
          // url="https://www.youtube.com/watch?v=rHqBAWf6tKY"
          playing={true}  // Automatically starts playing
          // controls={false} // Show player controls
          width="100%"    // Make player width 100% of the container
          height="100%"   // Make player height 100% of the container
          autoplay
        />
    </Box>

        <Container maxWidth={false}
          sx={{
            height: '100vh', 
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            backgroundColor: "#49BBBD60",
            position: "absolute",
            zIndex:"20"
          }}
      >
          <Container
          sx={{
            height: '100vh', 
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            // backgroundColor: "#49BBBD10"
          }}
        >
          
          <HeaderSection />
        </Container>
      </Container>
    </Container>
  );
};

export default Firstpage;



