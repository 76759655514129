import React, { useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import NewsNoticeCards from './news_notice.cards';
import apiClient from '../../config/axiosConfig';

const AllNews = () => {
    const [news, setNews] = useState([]);

    useEffect(() => {
        apiClient.get('/news').then((response) => {
            setNews(response.data.newsDetail);
        }).catch((error) => {
            console.error('Error fetching news:', error);
        });
    }, []);

    return (
        <Box>
            {/* <NewsNoticeCards info={news} sectionTitle="Latest News" /> */}
            <Stack direction="column" spacing={2}>
                <NewsNoticeCards info={news} sectionTitle="Latest News" />
            </Stack>
        </Box>
    );
};

export default AllNews;
