import React from 'react';
import { Typography, Box } from '@mui/material';

const TestimonialsHeader = () => {
  return (
    <Box>
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{
          fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
          fontWeight: 'bold',
        //   backgroundColor: 'Yellow',
          textTransform: 'uppercase',
          width: '100%',
          marginBottom:'2rem',
        }}
      >
        Student Voice
      </Typography>
    </Box>
  );
};

export default TestimonialsHeader;
