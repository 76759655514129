import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, MenuItem, Button, Select, FormControl, InputLabel, Stack } from '@mui/material';
import apiClient from '../../../config/axiosConfig';
import { toast } from 'react-toastify';

// Data for the dropdown options (uncomment and update if needed)
const abroadCourses = ['IELTS', 'PTE', 'GMAT', 'DSAT', 'DET'];
const nepalCourses = ['SOMAT', 'KUMAT', 'BBAFI', 'CMAT', 'KUUMAT'];
const qualificationOptions = ['+2 College', 'Bachelors', 'Others'];

const ApplyFormDialog = ({ open, handleClose, selectedCourse }) => {
  const [formData, setFormData] = useState({
    s_name: '',
    email: '',
    s_contact: '',
    s_address: '',
    graduated_college: '',
    destination: '',
    preparationTest: '',
    qualification: ''
  });

  useEffect(() => {
    if (selectedCourse) {
      const destination = abroadCourses.includes(selectedCourse) ? 'Study Abroad' : 'Study Nepal';
      setFormData((prevFormData) => ({
        ...prevFormData,
        destination,
        preparationTest: selectedCourse // Set selected course as preparation test
      }));
    }
  }, [selectedCourse]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate if all required fields are filled
    const requiredFields = ['s_name', 'email', 's_contact', 's_address', 'graduated_college', 'qualification', 'destination', 'preparationTest'];
    for (let field of requiredFields) {
      if (!formData[field]) {
        toast.error(`${field} is required!`, {
          position: "top-right",
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
        });
        return; // Stop the submission if any required field is missing
      }
    }

    // Get today's date for admission_on
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    console.log(formattedDate);

    console.log('Form Data Submitted:', formData);

    const studentApplication = {
      s_name: formData.s_name,
      email: formData.email,
      s_contact: formData.s_contact,
      s_address: formData.s_address,
      graduated_college: formData.graduated_college,
      admission_on: formattedDate,
      qualification: formData.qualification,
      destination: formData.destination,
      preparationTest: formData.preparationTest
    };
    console.log("Send above application:", studentApplication);

    apiClient.post(`/register`, studentApplication).then((response) => {
      console.log(response);
      if (response.data.success) {
        toast.success("Registration Success. Thank you.", {
          position: "top-right",
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
        });
      } else {
        toast.error("Sorry, something went wrong.", {
          position: "top-right",
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    }).catch((error) => {
      console.log(error);
    });

    handleClose();
    setFormData({
      s_name: '',
      email: '',
      s_contact: '',
      s_address: '',
      graduated_college: '',
      destination: '',
      preparationTest: '',
      qualification: ''
    });
  };

  return (
    <Dialog open={open} onClose={handleClose} sx={{ '& .MuiDialog-paper': { width:{ sm:'30%'}, maxWidth: 'none' } }}>
      <DialogTitle>Apply Form</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Stack container spacing={1}>
            {/* Name Field */}
            {/* <Grid item xs={12}> */}
              <TextField
                fullWidth
                margin="normal"
                label="Full Name"
                name="s_name"
                value={formData.s_name}
                onChange={handleInputChange}
                required
              />
            {/* </Grid> */}

            {/* Email Field */}
            {/* <Grid item xs={12}> */}
              <TextField
                fullWidth
                margin="normal"
                label="Email"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            {/* </Grid> */}

            {/* Phone Number Field */}
            {/* <Grid item xs={12}> */}
              <TextField
                fullWidth
                margin="normal"
                label="Phone Number"
                type="tel"
                name="s_contact"
                value={formData.s_contact}
                onChange={handleInputChange}
                required
              />
            {/* </Grid> */}

            {/* Address Field */}
            {/* <Grid item xs={12}> */}
              <TextField
                fullWidth
                margin="normal"
                label="Address"
                name="s_address"
                value={formData.s_address}
                onChange={handleInputChange}
                required
              />
            {/* </Grid> */}

            {/* Graduated College Field */}
            {/* <Grid item xs={12}> */}
              <TextField
                fullWidth
                margin="normal"
                label="Graduated College"
                name="graduated_college"
                value={formData.graduated_college}
                onChange={handleInputChange}
                required
              />
            {/* </Grid> */}

            {/* Qualification Dropdown */}
            {/* <Grid item xs={12}> */}
              <FormControl fullWidth>
                <InputLabel>Qualification</InputLabel>
                <Select
                  value={formData.qualification}
                  onChange={handleInputChange}
                  name="qualification"
                  label="Qualification"
                  required
                >
                  {qualificationOptions.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            {/* </Grid> */}

            {/* Destination Dropdown */}
            {/* <Grid item xs={12}> */}
              <FormControl fullWidth>
                <InputLabel>Destination</InputLabel>
                <Select
                  value={formData.destination}
                  onChange={handleInputChange}
                  name="destination"
                  label="Destination"
                  required
                >
                  <MenuItem value="Study Abroad">Study Abroad</MenuItem>
                  <MenuItem value="Study Nepal">Study In Nepal</MenuItem>
                </Select>
              </FormControl>
            {/* </Grid> */}

            {/* Preparation Test */}
            {/* <Grid item xs={12}> */}
              <TextField
                fullWidth
                margin="normal"
                label="Preparation Test"
                name="preparationTest"
                value={formData.preparationTest}
                onChange={handleInputChange}
                required
              />
            {/* </Grid> */}
          </Stack>


          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Submit Application
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ApplyFormDialog;
