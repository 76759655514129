import React from 'react';
import { Box, Typography, Container, List, ListItem, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FooterBar from '../../../layout/footer_bar/footer_bar_main';
import { useLocation } from 'react-router';
import { Buffer } from 'buffer';
import SocialMediaBar from '../../SocialMedia/socialMedia';

function CountryDetail() {
  const location = useLocation();
  const country = location.state;

  if (!country) {
    return <Typography variant="h6" align="center">Country data is missing or not available</Typography>;
  }

  const flagData = country.flag ? `url(data:image/${country.imageFormat};base64,${Buffer.from(country.flag).toString('base64')})` : null;

  return (
    <Box sx={{ backgroundColor: '#f5f5f5' }}>
      <Container sx={{ padding: "20px" }}>
        {/* Title Section */}
        <Typography
          variant="h4"
          gutterBottom
          align="center"
          sx={{
            fontWeight: 'bold',
            color: '#003366',
            fontFamily: "Roboto-Serif",
            textTransform: "uppercase",
            fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
          }}
        >
          {country.countryName}
        </Typography>

        {/* Image Section */}
        {flagData && (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              backgroundImage: flagData,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              borderRadius: '12px',
              objectFit: 'contain'
            }}
          />
        )}

        {/* Why Study Here Section */}
        <Box sx={{ textAlign: 'center', marginTop: { xs: '20px', md: '10px' } }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px', fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' } }}>
            Why Study Here?
          </Typography>
          <Typography variant="body1" sx={{ fontFamily: "Poppins", textAlign: 'justify', marginBottom: '20px', color: 'black', fontSize: { xs: '0.875rem', sm: '1rem', md: '1.125rem' } }}>
            {country.reasonToChoose}
          </Typography>
        </Box>

        {/* Guidelines Section */}
        <Box sx={{ textAlign: 'center', marginTop: { xs: '20px', md: '10px' } }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px', fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' } }}>
            Guidelines
          </Typography>
          {/* Render admissionGuideline as a bulleted list */}
          {country.admissionGuideline.length > 0 ? (
            <ul style={{ listStyleType: 'disc', paddingLeft: '20px', textAlign: 'left'}}>
              {country.admissionGuideline.map((guideline, index) => (
                <li key={index}>{guideline}</li>
              ))}
            </ul>
          ) : (
            <Typography variant="body1" align="center">No Guidelines available.</Typography>
          )}
        </Box>

        {/* Popular Universities Section */}
        <Box>
          <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'center', fontSize: { xs: '1.25rem', sm: '1.5rem', md: '1.75rem' } }}>
            Popular Universities:
          </Typography>
          {/* Render popular universities as a numbered list */}
          {country.popularUniversities && country.popularUniversities.length > 0 ? (
            <ol style={{ paddingLeft: '20px' }}>
              {country.popularUniversities.map((university, index) => (
                <li key={index}>{university}</li>
              ))}
            </ol>
          ) : (
            <Typography variant="body1" align="center">No popular universities available.</Typography>
          )}
        </Box>

        {/* FAQ Section */}
        <Box sx={{ marginTop: '20px' }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'center', fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' } }}>
            Frequently Asked Questions:
          </Typography>
          <Box sx={{ marginTop: '20px' }}>
            {country.faq && country.faq.length > 0 ? (
              country.faq.map((item, index) => (
                <Accordion key={index}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${index}-content`}
                    id={`panel${index}-header`}
                  >
                    <Typography sx={{ fontWeight: 'bold' }}>{item.faqQuestion}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{item.faqAnswer}</Typography>
                  </AccordionDetails>
                </Accordion>
              ))
            ) : (
              <Typography variant="body1" align="center">No FAQ data available.</Typography>
            )}
          </Box>
        </Box>

        <SocialMediaBar />
      </Container>
      <FooterBar />
    </Box>
  );
}

export default CountryDetail;
