import BookIcon from '@mui/icons-material/Book';
import PeopleIcon from '@mui/icons-material/People';
import SchoolIcon from '@mui/icons-material/School';
import QuizIcon from '@mui/icons-material/Quiz';  // Icon for mock tests
import MenuBookIcon from '@mui/icons-material/MenuBook'; // Icon for study material
import LocationOnIcon from '@mui/icons-material/LocationOn'; // Icon for college visits
import SupportIcon from '@mui/icons-material/Support'; // Icon for counseling

const chooseUsData=[
    {
        icon:BookIcon,
        chooseTitle:"Student Support"
    },
    {
        icon:PeopleIcon,
        chooseTitle:"Group Activities"
    },
    {
        icon:SchoolIcon,
        chooseTitle:"Certified Teachers"
    },
    {
        icon:QuizIcon,
        chooseTitle:"Daily/Weekly Mock Tests"
    },
    {
        icon:MenuBookIcon,
        chooseTitle:"Free Study Material"
    },
    {
        icon:LocationOnIcon,
        chooseTitle:"College Visits"
    },
    {
        icon:SupportIcon,
        chooseTitle:"Full Counseling"
    },
]
export default chooseUsData;