import React, { useEffect,useMemo, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import './App.css';

import { ToastContainer,Flip} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import About from './component/About/About'; 
import Contact from './component/Contact/Contact'; 
import StudyAbroad from './component/AbroadStudy/StudyAbroad'; 
import Navbar from './layout/nav_bar/nav_bar_main'; 
import StudyInNepal from './component/StudyNepal/StudyNepal.jsx';
import BachelorPrograms from './component/StudyNepal/ManagementItPrograms';
import AlumniStudentWithRank from './component/AliminiStudents/AluminiStudentWithRank'; 
import Firstpage from './component/pages/firstpage'; 

import DestinedCountry from './component/AbroadStudy/Country/destinedCountry.jsx';
import CountryDetail from './component/AbroadStudy/Country/countryDetail';
import AllNews from './component/section/newsSection.jsx';
import AllNotices from './component/section/noticeSection.jsx';

import NepaliCoursesDescriptionPage from './component/pages/nepaliCoursesDescription.page';
import AbroadCoursesDescriptionPage from './component/pages/abroadCoursesDescription.page';
import apiClient from './config/axiosConfig';

const App = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const showNavbar = location.pathname !== '/';
    const [students,setStudents] = useState([]);

    const [abroadCourses, setAbroadCourses] = useState([]);
    const [nepaliCourses,setNepaliCourses] = useState([]);

    const [filterAbroadCourses,setFilterAbroadCourses] = useState([]);
    const [filterNepaliCourses,setFilterNepaliCourses] = useState([]);

    useEffect(()=>{
        const fetchStudentsRecord = ()=>{
            apiClient.get('/register').then((response)=>{
                console.log("studenets detail");
                console.log(response)
                setStudents(response.data.StudentDetail);
            }).catch((error)=>{
                console.log(error);
            });
        }
        //for nepali students
        const fetchNepaliCourses = ()=>{
            apiClient.get(`/studynepalicourses`).then((response)=>{
                // console.log(response.data.studyNepalCourses);
                setNepaliCourses(response.data.studyNepalCourses);
            }).catch((error)=>{
                console.log(error)
            });
        }
        //for abroad students
        const fetchAbroadCourses = () => {
            apiClient.get(`/abroadcourses`).then((response) => {
              console.log("study abroad",response);
              setAbroadCourses(response.data.AbroadCourse);
            }).catch((error) => {
              console.log(error);
            });
          };
          fetchAbroadCourses();
        fetchNepaliCourses();
        fetchStudentsRecord();
    },[]);

    const studyInNepalStudent = useMemo(
        () => students.filter((student) => student.destination === 'Study In Nepal'&& student.feedback?.trim()),
        [students]
      );
    //   console.log(studyInNepalStudent)
      const studyAbroadStudent = useMemo(
        () => students.filter((student) => student.destination === 'Study Abroad'&& student.feedback?.trim()),
        [students]
      );
      console.log("Check Abroad:",abroadCourses);
      console.log("Check Nepali courses:",nepaliCourses);

      const abroadPreparationTests = useMemo(() => {
        if (abroadCourses.length > 0) {
            const extractedTitles = abroadCourses.map(course => 
                course.courseTitle.split('(')[0].trim()
            );
    
            // Remove duplicates
            return [...new Set(extractedTitles)];
        }
        return [];
        }, [abroadCourses]);
      const nepaliCoursePreparationTests = useMemo(() => {
        if (nepaliCourses.length > 0) {
            const extractedTitles = nepaliCourses.map(course => 
                course.courseTitle.split('(')[0].trim()
            );
    
            // Remove duplicates
            return [...new Set(extractedTitles)];
        }
        return [];
        }, [nepaliCourses]);
    
    console.log("Abroad Preparation Tests:", Array.isArray(abroadPreparationTests));
    console.log("Nepali Preparation Tests:", Array.isArray(nepaliCoursePreparationTests));
    const mergeCourses = nepaliCoursePreparationTests.concat(abroadPreparationTests);
    console.log("this is merge:",mergeCourses);
    
    //   console.log("Abroad Courses Filter:",abroadPreparationTest);
     console.log(studyAbroadStudent)
    
    return (
        <>
            {showNavbar && <Navbar prepCourses={mergeCourses} />}
            <Routes>
                <Route path="/" element={<Firstpage onNavigate={() => navigate('/home')} />} />
                
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/study-abroad" element={<StudyAbroad studentTestimonial={studyAbroadStudent} abroadCourses={abroadCourses}/>} /> 
                <Route path="/study-in-nepal" element={<StudyInNepal studentTestimonial={studyInNepalStudent} nepaliCourses={nepaliCourses}/>} /> 
                <Route path="/bachelor-programs" element={<BachelorPrograms />} />
                
                <Route path="/alumni" element={<AlumniStudentWithRank studentRecord={students}/>} />
                <Route path="/destination-country" element={<DestinedCountry/>} />
                <Route path="/:countryId/:id" element={<CountryDetail/>} />
                
                {/* <Route path="/desc" element={<NepaliCoursesDescriptionPage/>} />
                <Route path="/abroad-course-desc" element={<AbroadCoursesDescriptionPage/>} /> */}
                <Route path="/desc" element={<NepaliCoursesDescriptionPage />} />
                <Route path="/abroad-course-desc" element={<AbroadCoursesDescriptionPage />} />

                <Route path="/news" element={<AllNews />} />
                <Route path="/notices" element={<AllNotices />} />

                {/* // <Route path="/newzealand-study" element={<NewzeelandStudy/>}/> */}
            </Routes>
            <ToastContainer 
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition= {Flip}
            />
        </>
    );
};

const MainApp = () => (
    <Router>
        <App />
    </Router>
);

export default MainApp;






