import React, { useState } from 'react';
import {
  Box,
  Typography,
  Container,
  Card,
  CardContent,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FooterBar from '../../layout/footer_bar/footer_bar_main';
import ApplicationFormDialog from '../Courses/courseform'; 
import { useLocation } from 'react-router';
import SocialMediaBar from '../SocialMedia/socialMedia';

const NepaliCoursesDescriptionPage = () => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const location = useLocation();
  const coursesReceived = location.state || {}; // Default to empty object if no state is passed

  // Safely handle undefined courseTitle before calling .split()
  const courseTitle = coursesReceived.courseTitle || '';  // Fallback to empty string if undefined
  const preparation = courseTitle ? courseTitle.split('(')[0].split(' ')[0] : ''; // Safe split

  // Ensure courseInfo has fallback values for all its properties
  const courseInfo = {
    courseType: 'Study In Nepal', // Default value if courseType is not available
    preparation: preparation || '', // Default empty string if preparation is undefined
  };

  const handleDialogOpen = () => setDialogOpen(true);
  const handleDialogClose = () => setDialogOpen(false);

  // Safe fallback for undefined arrays
  const eligibility = Array.isArray(coursesReceived.eligibility) ? coursesReceived.eligibility : [];
  const examPattern = Array.isArray(coursesReceived.examPattern) ? coursesReceived.examPattern : [];
  const syllabus = Array.isArray(coursesReceived.syllabus) ? coursesReceived.syllabus : [];
  const preparationTips = Array.isArray(coursesReceived.preparationTips) ? coursesReceived.preparationTips : [];
  const faq = Array.isArray(coursesReceived.faq) ? coursesReceived.faq : [];

  return (
    <Box sx = {{backgroundColor:'red'}}>
      <Container maxWidth="lg"
      >
        <Typography variant="h4" component="h1" gutterBottom align="center" 
          sx={{ fontWeight: 'bold', color: '#003366',mt:4}}>
          {coursesReceived.courseTitle}
        </Typography>

        <Typography variant="h6" fontWeight="550" fontFamily='Roboto-Serif' sx={{ letterSpacing: "1px" }} gutterBottom>
          Overview
        </Typography>
        <Typography variant="body1" component='p' gutterBottom>
          {coursesReceived.overview}
        </Typography>

        <Typography variant="h6" component="h2" fontWeight="550" fontFamily='Roboto-Serif' sx={{ letterSpacing: "1px" }} gutterBottom>
          Eligibility
        </Typography>
        <ul>
          {eligibility.map((item, index) => (
            <li key={index}>
              <Typography variant="body1">{item}</Typography>
            </li>
          ))}
        </ul>

        <Typography variant="h6" component="h2" fontWeight="550" fontFamily='Roboto-Serif' sx={{ letterSpacing: "1px" }} gutterBottom>
          Exam Pattern
        </Typography>
        <Card sx={{ marginBottom: 2 }}>
          <CardContent>
            {examPattern.map((pattern, index) => (
              <Typography key={index} variant="body1">
                <strong>{pattern.topic}:</strong> {pattern.description}
              </Typography>
            ))}
          </CardContent>
        </Card>

        <Typography variant="h6" component="h2" fontWeight="550" fontFamily='Roboto-Serif' sx={{ letterSpacing: "1px" }} gutterBottom>
          Syllabus
        </Typography>
        {syllabus.map((subject, index) => (
          <Accordion key={index}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}a-content`} id={`panel${index}a-header`} sx={{ margin: 0, marginLeft: "10px", padding: 0 }}>
              <Typography variant='body1' fontWeight='550'>{subject.topic}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "0px", margin: "0px" }}>
              <ul style={{ padding: 0, margin: "0px 0px 10px 30px" }}>
                {subject.subtopics.map((subTopic, idx) => (
                  <li key={idx}>
                    <Typography variant="body2">{subTopic}</Typography>
                  </li>
                ))}
              </ul>
            </AccordionDetails>
          </Accordion>
        ))}

        <Typography variant="h6" component="h2" fontWeight="550" fontFamily='Roboto-Serif' sx={{ letterSpacing: "1px" }} gutterBottom>
          Preparation Tips
        </Typography>
        <ul>
          {preparationTips.map((tip, index) => (
            <li key={index}>
              <Typography variant="body1">{tip}</Typography>
            </li>
          ))}
        </ul>

        <Typography variant="h6" component="h2" fontWeight="550" fontFamily='Roboto-Serif' sx={{ letterSpacing: "1px" }} gutterBottom>
          Frequently Asked Questions
        </Typography>
        {faq.map((faqItem, index) => (
          <Accordion key={index}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}b-content`} id={`panel${index}b-header`}>
              <Typography fontWeight='550'>{faqItem.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{faqItem.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}

        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Button variant="contained" onClick={handleDialogOpen} sx={{
            mr: 2,
            backgroundImage: 'linear-gradient(90deg, #49BBBD 0%, #136CB5 100%)',
            color: 'white',
            '&:hover': { backgroundImage: 'linear-gradient(90deg, #136CB5 0%, #49BBBD 100%)' }
          }}>
            Apply Now
          </Button>
          <Button variant="outlined" color="secondary" href="/contact">
            Contact Us
          </Button>
        </Box>
        
      </Container>
     
      <SocialMediaBar/>
      <ApplicationFormDialog open={dialogOpen} handleDialogClose={handleDialogClose} courseInfo={courseInfo}/>
       <FooterBar />
    </Box>
    
  );
};

export default NepaliCoursesDescriptionPage;
