
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import NewsNoticeCards from './news_notice.cards';
import apiClient from '../../config/axiosConfig';

const NoticeSection = () => {
    const [notices, setNotices] = useState([]);

    useEffect(() => {
        const fetchNotices = () => {
            apiClient.get('/notices').then((response) => {
                setNotices(response.data.noticeDetail);
            }).catch((error) => {
                console.error('Error fetching notices:', error);
            });
        };
        fetchNotices();
    }, []);

    return (
        <Box>
            <NewsNoticeCards info={notices} sectionTitle={'Notices'} />
        </Box>
    );
};

export default NoticeSection;




// import React, { useEffect, useState } from 'react';
// import { Box } from '@mui/material';
// import NewsNoticeCards from './news_notice.cards';
// import apiClient from '../../config/axiosConfig';

// const AllNotices = () => {
//     const [notices, setNotices] = useState([]);

//     useEffect(() => {
//         apiClient.get('/notices').then((response) => {
//             setNotices(response.data.noticeDetail);
//         }).catch((error) => {
//             console.error('Error fetching notices:', error);
//         });
//     }, []);

//     return (
//         <Box>
//             <NewsNoticeCards info={notices} sectionTitle="Notices" />
//         </Box>
//     );
// };

// export default AllNotices;