import React, { useEffect, useState } from "react";
import {
    Typography, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Button, Stack
} from '@mui/material';
import { Info, Announcement } from '@mui/icons-material';

const NewsNoticeCards = ({ info, sectionTitle }) => {
    const [showMore, setShowMore] = useState(false);
    const [chooseIcon, setChooseIcon] = useState(false);
    const [openDialog, setOpenDialog] = useState(false); // State to open/close dialog
    const [selectedItem, setSelectedItem] = useState(null); // State to store the selected card item

    useEffect(() => {
        if (sectionTitle === 'Latest News') {
            setChooseIcon(true);
        }

        // Log the fetched data
        console.log("Fetched data for section:", sectionTitle);
        console.log(info); // Logs the array of fetched news or notices
    }, [info, sectionTitle]);

    // Function to convert Buffer to an image URL
    const bufferToImage = (buffer) => {
        if (buffer && buffer.data && buffer.data.length > 0) {
            const blob = new Blob([new Uint8Array(buffer.data)], { type: 'image/jpeg' });
            return URL.createObjectURL(blob);
        }
        return null;
    };

    // Function to open dialog and set the selected item
    const handleCardClick = (item) => {
        setSelectedItem(item);
        setOpenDialog(true);

        // Log the selected item when a card is clicked
        console.log("Selected Item:", item);
    };

    return (
        <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            spacing={2}
            sx={{
                width: '100%',
            }}
        >
            <Typography
                variant="h5"
                align="center"
                gutterBottom
                fontFamily="Roboto-Serif"
                sx={{
                    fontWeight: 'bold',
                    color: '#003366',
                    fontSize: { xs: "22px", sm: "24px", md: "32px", lg: "42px" }
                }}
            >
                {sectionTitle}
            </Typography>

            <Stack
                direction="row"
                spacing={2} 
                justifyContent="center"
                sx={{
                    width: '100%',
                    flexWrap: 'wrap',
                    gap: 2,  // Ensure there's a gap between the cards horizontally
                }}
            >
                {info.slice(0, showMore ? info.length : 2).map((item) => (
                    <Card
                        key={item.id}
                        sx={{
                            textAlign: 'center',
                            transition: 'transform 0.3s',
                            height: 160,
                            margin: '0 auto',
                            borderRadius: 1,
                            backgroundColor: '#ffffff',
                            width: { xs: 280, sm: 400 },
                            '&:hover': {
                                transform: 'scale(1.05)',
                                boxShadow: 6,
                            },
                        }}
                        onClick={() => handleCardClick(item)}
                    >
                        <CardContent>
                            {
                                chooseIcon ? <Info style={{ fontSize: 40, color: '#003366' }} /> :
                                    <Announcement style={{ fontSize: 40, color: '#003366' }} />
                            }
                            <Typography variant="body1" fontFamily="Roboto-Serif" sx={{ fontWeight: 'bold', marginTop: 1 }}>
                                {sectionTitle === 'Latest News' ? item.news_title : item.notice_title}
                            </Typography>
                            <Typography variant="body1" fontFamily="Roboto-Serif" sx={{ fontStyle: 'italic', marginTop: 1 }}>
                                {sectionTitle === 'Latest News' ? item.news_date : item.notice_on}
                            </Typography>
                        </CardContent>
                    </Card>
                ))}
            </Stack>

            <Button
                onClick={() => setShowMore(!showMore)}
                sx={{
                    backgroundColor: '#49BBBD',
                    fontSize: { xs: "12px", sm: "16px" },
                    '&:hover': { backgroundColor: '#ff5722' },
                    color: 'white',
                    marginTop: 2,
                    marginBottom: { xs: 3 },
                    width: 'auto',  
                    paddingX: 4,   
                }}
            >
                {showMore ? "View Less" : "View More"}
            </Button>

            {/* Dialog Popup for Detailed View */}
            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                maxWidth="sm" // Define a max width for small screens
                fullWidth
                sx={{
                    '@media (max-width:600px)': {
                        width: '90%', // For small screens (xs), set width to 90%
                    },
                    '@media (min-width:600px) and (max-width:960px)': {
                        width: '80%', // For medium screens (sm and md), set width to 80%
                    },
                    '@media (min-width:960px)': {
                        width: '100%', // For large screens (lg), set width to 60%
                    }
                }}
            >
                <DialogTitle>{sectionTitle === 'Latest News' ? selectedItem?.news_title : selectedItem?.notice_title}</DialogTitle>
                <DialogContent
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center', // Center the content horizontally
                        justifyContent: 'center', // Center the content vertically
                        
                    }}
                >
                    {/* Check if the news/notice has an image */}
                    {sectionTitle === 'Latest News' && selectedItem?.news_photo?.data?.length > 0 ? (
                        <img
                            src={bufferToImage(selectedItem.news_photo)}
                            alt="News Photo"
                            style={{
                                objectFit: 'contain',
                                width: '100%', // Set the image width to 70%
                                height: 'auto',
                                maxHeight: '500px',  
                                marginBottom: 16,
                                margin: '0 auto', // Center the image
                            }}
                        />
                    ) : sectionTitle === 'Notices' && selectedItem?.notice_photo?.data?.length > 0 ? (
                        <img
                            src={bufferToImage(selectedItem.notice_photo)}
                            alt="Notice Photo"
                            style={{
                                width: '100%', // Set the image width to 70%
                                height: 'auto',
                                maxHeight: '500px',
                                objectFit: 'contain', // Ensure the image fits without cropping or distortion
                                marginBottom: 16,
                                margin: '0 auto', // Center the image
                            }}
                        />
                    ) : null}

                    {/* Display the news/notice details */}
                    <Typography variant="body1" sx={{ marginTop: 2 }}>
                        {sectionTitle === 'Latest News' ? selectedItem?.news_detail : selectedItem?.notice_detail}
                    </Typography>

                    {/* Display the date */}
                    <Typography variant="body2" sx={{ marginTop: 2, fontStyle: 'italic' }}>
                        Date: {sectionTitle === 'Latest News' ? selectedItem?.news_date : selectedItem?.notice_on}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setOpenDialog(false)}
                        sx={{
                            backgroundColor: '#49BBBD',
                            '&:hover': { backgroundColor: '#ff5722' },
                            color: 'white',
                        }}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Stack>
    );
};

export default NewsNoticeCards;



